<p-blockUI [blocked]="blockedPanel" >
  <!-- <p-progressSpinner [style]="{position: 'relative', top: '45%', left: '45%'}" style="background-color: transparent !important;" ></p-progressSpinner> -->
  <div class="custom-message-container">
  <div class="la-ball-atom" >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <span  class="font-14 custom-message">{{customMessage}}</span>
</div>
</p-blockUI>