<!-- SIGNUP PAGE -->
<!-- <div class="container-fluid signin" (window:resize)="onResize($event)" [style.minHeight.px]="contentInnerHeight">
    <div class="inner-page">
        <div id="rock1"></div>
        <div id="rock2"></div>
        <div id="rock3"></div>
        <div id="rock4"></div>
        <div id="rock5"></div>
        <div id="rock6"></div>
        <div id="rock7"></div>
        <div id="rock8"></div>
        <div id="rock9"></div>
        <div id="rock10"></div>
        <div id="rock11"></div>
        <div id="rock12"></div>
        <div id="rock13"></div>
        <div id="rock14"></div>
        <div id="rock15"></div>
        <div id="rock16"></div>
        <div class="login-box row mx-0 font-roboto-cad">
            <h3 class="title3 text-white text-center w-100 font-25">Sign Up</h3>
            <div class="row mx-auto w-100 my-4">
                <img src="assets/img/signin-icon-white.png" alt="" class="logo1 mx-auto">
            </div>
            <div class="row mx-auto my-4">
                <img src="assets/img/Teamium-white.png" alt="" class="img-fluid logo2 mx-auto">
                <p class="font-15 w-100 text-center text-white mt-2">PRODUCTION PLATFORM</p>
            </div>
            <form action="" class="w-100" style="display: grid;">
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="row mx-0">
                        <div class="row mx-0 input-group mb-4">
                            <div class="form-group w-100" [ngClass]="{'valid': username.length > 0 }">
                                <input type="text" name="username" class="form-control modal-text-box" value="" [(ngModel)]="username" required>
                                <label class="label-control">User Name</label>
                            </div>
                        </div>
                        <div class="row mx-0 input-group mb-4">
                            <div class="form-group w-100" [ngClass]="{'valid': email.length > 0 }">
                                <input type="email" name="email" class="form-control modal-text-box" value="" [(ngModel)]="email" required>
                                <label class="label-control">Email</label>
                            </div>
                        </div>
                        <div class="row mx-0 input-group mb-4">
                            <div class="form-group w-100" [ngClass]="{'valid': password.length > 0 }">
                                <input type="password" class="form-control modal-text-box" value="" name="password" [(ngModel)]="password" required>
                                <label class="label-control">Password</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <button class="btn btn-account font-bold" type="button">Sign Up Now</button>
                    </div>
                    <div class="row mx-0 font-roboto-cad mt-3 link-box">
                        <div class="col-xl-6 login-button mx-auto">
                            <button class="btn btn-signin mx-2" (click)="goSignin()">
                                <i class="fas fa-sign-in-alt"></i>
                            </button>
                            <div class="t-popover">
                                {{'Already have an account? Sign in' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->

<div class="maincont">
    <mat-stepper linear #stepper >
        <mat-step  >
            <form >
                <ng-template matStepLabel>Fill out your Basic Details</ng-template>
                <div class="dtcntn">
                    <div class="row">
                        <div class="col-6 xbtnx">
                            <mat-form-field style="width: 67%;">
                                <mat-label>First Name</mat-label>
                                <input matInput  placeholder="First name" required>
                            </mat-form-field>
                        </div>

                        <div class="col-6 zbtnx">
                            <mat-form-field style="width: 67%;" >
                                <mat-label> Last Name</mat-label>
                                <input matInput  placeholder="Last name" required>
                            </mat-form-field>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-6 xbtnx">
                            <mat-form-field style="width: 67%;" >
                                <mat-label> Upload image</mat-label>
                                <input matInput  placeholder="Please Upload Your Image" required>
                            </mat-form-field>
                        </div>

                        <div class="col-6 zbtnx">
                            <mat-form-field style="width: 67%;" >
                                <mat-label> Telephone</mat-label>
                                <input matInput  placeholder="Telephone" required>
                            </mat-form-field>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-6 xbtnx" >
                            <mat-form-field style="width: 67%;">
                                <mat-label> Email</mat-label>
                                <input matInput  placeholder="Email" required>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="row footermainbtn" >
                    <div class="col-1 footerbtn">
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step >
            <form>
                <ng-template matStepLabel>Fill out your address</ng-template>
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Address </mat-label>
                            <input matInput  placeholder="Ex. 1 Main St, New York, NY" required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> City </mat-label>
                            <input matInput  placeholder="Ex. New York" required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> State </mat-label>
                            <input matInput  placeholder="Ex. New York" required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Zip Code </mat-label>
                            <input matInput  placeholder="Ex. 10001" required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Country </mat-label>
                            <input matInput  placeholder="Ex. United State" required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row footermainbtn" >
                    <div class="col-1 footerbtn">
                        <button mat-button matStepperPrevious>Back</button>
                    </div>
                    <div class="col-1 ml-3 footerbtn">
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step  >
            <form >
                <ng-template matStepLabel>Fill out Language & skills Details</ng-template>
                <div class="row ">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label>Select Language</mat-label>
                            <input matInput  placeholder="Ex. English,Frech" required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;" >
                            <mat-label> Select Skills</mat-label>
                            <input matInput  placeholder="Ex. C100" required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label>  Select market place</mat-label>
                            <input matInput  placeholder="Select market place" required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Payment by invoice</mat-label>
                            <input matInput  placeholder="Ex. Yes/No" required>
                        </mat-form-field>
                    </div>
                </div> 
            <div class="row footermainbtn" >
                <div class="col-1 footerbtn">
                    <button mat-button matStepperPrevious>Back</button>
                </div>
                <div class="col-1 ml-3 footerbtn">
                    <button mat-button matStepperNext>Next</button>
                </div>
            </div>         

            </form>
        </mat-step>
        <mat-step >
            <form>
                <ng-template matStepLabel>Fill out your Personnel Details</ng-template>
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Social Security Number </mat-label>
                            <input matInput  placeholder="Ex. Exp0098" required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Gender </mat-label>
                            <input matInput  placeholder="Ex. Male/Female" required > 
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Birth Date </mat-label>
                            <input matInput  placeholder="Ex. 16/05/1986" required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label>Place Of Birth </mat-label>
                            <input matInput  placeholder="Ex. California " required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> State Of Birth </mat-label>
                            <input matInput  placeholder="Ex. California " required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Country Of Birth </mat-label>
                            <input matInput  placeholder="Ex. United State " required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Select Citizenship</mat-label>
                            <input matInput  placeholder="Ex. California " required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Press Card Number </mat-label>
                            <input matInput  placeholder="Ex. 90xxxxxx673L" required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Talent Registration</mat-label>
                            <input matInput  placeholder="Ex. California " required>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field style="width: 67%;">
                            <mat-label> Routing and Account </mat-label>
                            <input matInput  placeholder="Ex. 16581321 " required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-6 xbtnx">
                        <mat-form-field style="width: 67%;">
                            <mat-label> BIC </mat-label>
                            <input matInput  placeholder="ex. URGSJD " required>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row footermainbtn" >
                    <div class="col-1 footerbtn">
                        <button mat-button matStepperPrevious>Back</button>
                    </div>
                    <div class="col-1 ml-3 footerbtn">
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <form>
                <ng-template matStepLabel>Google calender</ng-template>
            <div class="row ">
                <div class="col-6 xbtnx">
                    <mat-form-field style="width: 67%;">
                        <mat-label>Calender Access</mat-label>
                        <input matInput  placeholder="Ex. English,Frech" required>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field style="width: 67%;" >
                        <mat-label> Select Skills</mat-label>
                        <input matInput  placeholder="Ex. C100" required>
                    </mat-form-field>
                </div>
            </div> 

            <div class="row footermainbtn" >
                <div class="col-1 footerbtn">
                    <button mat-button matStepperPrevious>Back</button>
                </div>
                <div class="col-1 ml-3 footerbtn">
                    <button mat-button matStepperNext>SAVE</button>
                </div>
            </div>
            </form>
        </mat-step>
    </mat-stepper>