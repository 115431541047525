<!-- START THE TOUR PAGE -->
<div class="container-fluid px-0 signin" (window:resize)="onResize($event)" [style.minHeight.px]="myInnerHeight">
    <div class="login-box row mx-0 font-roboto-cad" style="padding: 10% 0">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mx-auto">
            <h3 class="title3 text-white text-center font-20">Let's Start</h3>
            <div class="row mx-auto w-100 my-4">
                <img src="assets/img/signin-icon-white.png" alt="" class="logo1 mx-auto">
            </div>
            <div class="row mx-auto my-4">
                <img src="assets/img/Teamium-white.png" alt="" class="img-fluid logo2 mx-auto">
                <p class="font-15 w-100 text-center text-white mt-2">PRODUCTION PLATFORM</p>
            </div>
            <div class="row mx-auto w-100 my-4" style="position: relative; z-index: 100;">
                <button class="btn btn-account" type="button" (click)="goToWelcomeScreen()">{{'Start the tour' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="area" >
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
</div>