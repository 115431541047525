import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()

export class OktaService {
    clientid:any=null;
 
    constructor(private oauthService: OAuthService, private router: Router) {
        if (window.location.hostname == 'canal9.teamium.net'){
            this.clientid = '0oa3vv177rZUZ1P3O417'
          } else if (window.location.hostname == 'qa.teamium.net'){
            this.clientid = '0oaic2t62O6GMjpBt4x6'
          } else {
            this.clientid = '0oaic2t62O6GMjpBt4x6'
          }
    
    }

    public okta: AuthConfig = {
        issuer: 'https://dev-201250.okta.com/oauth2/default',
        oidc: true,
        redirectUri: window.location.origin +'/signin',
        clientId: this.clientid,
        scope: 'openid profile email',
        showDebugInformation: true,
    };

  



    public configure() {
        const oauthService = this.oauthService;
        const router = this.router;
        this.okta.clientId = this.clientid;
        oauthService.configure(this.okta);
        oauthService.tokenValidationHandler = new JwksValidationHandler();
        oauthService.showDebugInformation = true;
        oauthService.loadDiscoveryDocument().then((doc) => {
            oauthService.tryLogin({
                onTokenReceived: (context) => {
                    console.log(context)
                    router.navigateByUrl(context.state);
                },
                onLoginError: (context) => {
                    
                }
            });
        });
    }

    public getClaims() {
        return this.oauthService.getIdentityClaims();
    }
}