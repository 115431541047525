import { AfterViewInit, ChangeDetectorRef,Output, EventEmitter,Component, NgZone, ElementRef, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
// import * as _ from 'lodash';
import 'chart.js';
import { ProjectListService } from 'src/app/core/services/project-list/project-list.service';
import { SharedService } from 'src/app/core/services/shared-service/shared.service';
import { CommonUtilService } from 'src/app/core/services/common-util-service';
import { style } from '@angular/animations';
declare var $: any;



@Component({
  selector: 'app-booking-work-order',
  templateUrl: './booking-work-order.component.html',
  styleUrls: ['./booking-work-order.component.scss']
})
export class BookingWorkOrderComponent implements OnInit, AfterViewInit {
  @Output() apiCallComplete = new EventEmitter<void>();


  dateobj: any
  bookingOrderArr: any = [];
  blockedPanel1: any = false;
  spreadsheetFile: any
  objectURL: any
  personalList: any = [];
  public chart: any = null;
  ViewChart: any = false;
  xAxisLabel: any = '';
  labelArr: any = []
  dataSet: any = []
  dataSetActual: any = []
  bgColorArr:any =[];
  bgColorArrA:any =[];
  xlabelG:any = null;
  yLabelG:any = null;
  chartTypeG:any = null;
  sumTotalActual:any = 0;
  sumTotalActualCost:any = 0;
  sumTotalPrice:any = 0;
  sumTotalCost:any = 0;

  constructor(private elementRef: ElementRef, private projectService: ProjectListService, private zone: NgZone, private commonUtilService: CommonUtilService, private sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    // this.getProjectTableData()
  }

  callApi(showCart) {
    this.zone.run(() => {
      this.getPersonals(showCart);

    })
  }

  viewChartFn(xlabel: any, yLabel:any,chartType) {
    this.zone.run(() => {
      this.ViewChart = true;
      this.xlabelG = xlabel;
      this.yLabelG = yLabel;
      this.chartTypeG = chartType;
      this.createChart(xlabel, yLabel,chartType)
    })
  }

  viewTableFn() {
    this.zone.run(() => {
      this.ViewChart = false;
      if ($.fn.dataTable.isDataTable('#table_id')) {
        let table = $('#table_id').DataTable();
        table.clear()
        table.destroy();
      }

      $(document).ready(function () {
        $('#table_id').DataTable({
          order: [[2, 'desc']],
          "bPaginate": false,
          "bFilter": false,
          "bInfo": false
        });
      });
      // this.createChart()
    })
  }

  // async getPersonals() {
  //   try {
  //     if (this.sharedService.getglobalPersonel().length != 0) {
  //       this.personalList = this.sharedService.getglobalPersonel();
  //     } else {
  //       try {
  //         const res: any = await this.commonUtilService.getAllPersonel();
  //         if (res.length > 0) {
  //           this.sharedService.multipleCallRemover(res,'personalList');
  //         }
  //       } catch (error) {

  //       }
  //     }
  //   } catch (error) {

  //   }

  // }

  async getPersonals(showCart) {
    
    try {
      this.commonUtilService.isProcessinggetAllPersonelProjectById = false;
      this.commonUtilService.requestQueuegetAllPersonelProjectById = [];
          const res: any = await this.commonUtilService.getAllPersonelProjectById();
        this.personalList = [...res];
      this.getProjectTableData(showCart)


  
  
    } catch (error) {
      console.log(error)
      // this.blockedPanel = false;
      
    }
  }

  returnPersonalName(id) {
    let index = this.personalList.findIndex(el => el.id == id)
    if (index != -1) {
      return `${this.personalList[index].fullName}`
    }
  }

  async getProjectTableData(showCart:any) {
    try {
      this.blockedPanel1 = true;
      if (this.sharedService.getreportDatesData() != undefined) {
        this.dateobj = this.sharedService.getreportDatesData();
      }
      this.bookingOrderArr = [];
      if ($.fn.dataTable.isDataTable('#table_id')) {
        let table = $('#table_id').DataTable();
        table.clear()
        table.destroy();
      }

      const res: any = await this.projectService.getBookingOrderData(this.dateobj);
      this.bookingOrderArr = res.bookings || [];
       this.sumTotalActual = 0;
       this.sumTotalActualCost = 0;
       this.sumTotalPrice = 0;
       this.sumTotalCost = 0;

      for (let i = 0; i < this.bookingOrderArr.length; i++) {
        if(this.bookingOrderArr[i].totalActual){
          this.sumTotalActual += Number(this.bookingOrderArr[i].totalActual)
        }
        if(this.bookingOrderArr[i].totalActualCost){
          this.sumTotalActualCost += Number(this.bookingOrderArr[i].totalActualCost)
        }
        if(this.bookingOrderArr[i].totalPrice){
          this.sumTotalPrice += Number(this.bookingOrderArr[i].totalPrice)
        }
        if(this.bookingOrderArr[i].totalCost){
          this.sumTotalCost += Number(this.bookingOrderArr[i].totalCost)
        }
        if (this.bookingOrderArr[i].projectTheme != null) {
          this.bookingOrderArr[i].color = this.setContrast(this.bookingOrderArr[i].projectTheme)
        } else {
          this.bookingOrderArr[i].color = 'black'
        }

        if (this.bookingOrderArr[i].functionTheme != null) {
          this.bookingOrderArr[i].fnColor = this.setContrast(this.bookingOrderArr[i].functionTheme)
        } else {
          this.bookingOrderArr[i].fnColor = 'black'
        }


      }

      this.spreadsheetFile = res.spreadsheetFile
      this.blockedPanel1 = false;
      this.apiCallComplete.emit();


      $(document).ready(function () {
        $('#table_id').DataTable({
          order: [[2, 'desc']],
          "bPaginate": false,
          "bFilter": false,
          "bInfo": false
        });
      });

      console.log(showCart)

      if(showCart){
        this.createChart(this.xlabelG, this.yLabelG,this.chartTypeG)
      }


    } catch (error) {
      console.log(error)
      this.blockedPanel1 = false;
      this.apiCallComplete.emit();



    }
  }

  downloadSheet() {
    try {
      let sheetData = this.spreadsheetFile;
      let bin = atob(sheetData);
      let buf = new ArrayBuffer(bin.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i != bin.length; ++i) view[i] = bin.charCodeAt(i) & 0xFF;
      let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      this.objectURL = window.URL.createObjectURL(blob);
      this.sharedService.setbolbData(this.objectURL);
      let anchor = document.createElement('a');
      anchor.href = this.objectURL;
      anchor.download = `Booking-Report.xlsx`;
      anchor.click();
      URL.revokeObjectURL(this.objectURL);

    }
    catch (err) {

    }

  }

   PrintImage() {
    // var canvas:any = document.querySelector("#yourCavasId");
    var canvas =  document.getElementById('yourCavasId') as HTMLCanvasElement;
    let imgData = canvas.toDataURL('image/jpeg')
    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Print canvas</title></head>';
    windowContent += '<body>'
    windowContent += '<img  src="' + imgData + '" >';
    windowContent += '</body>';
    windowContent += '</html>';
    var printWin = window.open('', '',);
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    // printWin.close();

}

  hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    // return {r, g, b} // return an object
    return [r, g, b]
  }

  hex2rgbChart = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return `rgb(${r}, ${g}, ${b})`
  }
  setContrast(hexCode) {
    if (hexCode == undefined || hexCode == null  || hexCode == '' || !hexCode.toString().includes("#")) {
      return
    }
    let rgb: any
    if (hexCode.includes('#')) {
      rgb = this.hex2rgb(hexCode)
    } else {
      return 'black'
    }
    const brightness = Math.round(((parseInt(rgb[0]) * 299) +
      (parseInt(rgb[1]) * 587) +
      (parseInt(rgb[2]) * 114)) / 1000);
    const textColour = (brightness > 125) ? 'black' : 'white';
    return textColour
  }

  async createChart(xAxisLabel: any, yLabel: any,chartType:any) {
    await this.getLables(xAxisLabel, yLabel)

    setTimeout(() => {
      let htmlRef = this.elementRef.nativeElement.querySelector(`#yourCavasId`);
      if (this.chart != null) {
        this.chart.clear()
        this.chart.destroy()
      }
      this.chart = new Chart(htmlRef, {
        type: this.getChartType(chartType), //this denotes tha type of chart

        data: this.getDataSet(chartType,yLabel),
        options: this.getOptionsVaue(chartType,yLabel)

      });
    }, 300);

    this.apiCallComplete.emit();

  }

  getOptionsVaue(type,yLabel){
    let obj = {}
    if(type == 'Line Chart'){
      obj =  {// values on X-Axis
        aspectRatio: 2.5,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: yLabel ,
            },
            ticks: {
              beginAtZero: type == 'Line Chart' ? false : true
          }
          }]
        },
      }
      return obj
    } else if(type == 'Bar Chart'){
      obj =  {// values on X-Axis
        aspectRatio: 2.5,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: yLabel ,
            },
            ticks: {
              beginAtZero: type == 'Line Chart' ? false : true
          }
          }]
        },
      }
      return obj
    }  else if(type == 'Radar Chart'){
      obj =  {// values on X-Axis
        elements: {
          line: {
            borderWidth: 3
          }
        }
      }
      return obj
    } else if(type == 'Pie Chart'){
      obj =  { }
      return obj
    }else if(type == 'Donut Chart'){
      obj =  { }
      return obj
    }else if(type == 'Area Chart'){
      obj =  { }
      return obj
    } else {
      obj =  {// values on X-Axis
        aspectRatio: 2.5,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: yLabel ,
            },
            ticks: {
              beginAtZero: type == 'Line Chart' ? false : true
          }
          }]
        },
      }
      return obj
    }
   
  }

  getDataSet(type:any,yLabel){

   let obj =  {}
    if(type == 'Line Chart'){
      obj =  {// values on X-Axis
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label:'Actual' + yLabel,
            data: this.dataSetActual,
            fill: false,
            borderColor: 'rgb(151, 151, 151)',
            tension: 0.1
          }
        ]
      }
      return obj
    } else if(type == 'Bar Chart'){
      obj =  {// values on X-Axis
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            backgroundColor: this.bgColorArr,
            borderWidth: 1
          },
          {
            label:'Actual' + yLabel ,
            data: this.dataSetActual,
            backgroundColor: this.bgColorArrA,
            borderWidth: 1
          }
        ]
      }
      return obj
    }  else if(type == 'Radar Chart'){
      obj =  {// values on X-Axis
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
          },
          {
            label: 'Actual' + yLabel ,
            data: this.dataSetActual,
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
          }
        ]
      }
      return obj
    } else if(type == 'Pie Chart'){
      obj =  { 
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            backgroundColor:this.bgColorArr,
            hoverOffset: 4
          },
          {
            label: 'Actual' + yLabel,
            data: this.dataSetActual,
            backgroundColor:this.bgColorArrA,
            hoverOffset: 4
          }
        ]
      }
      return obj
    }else if(type == 'Donut Chart'){
      obj =  {
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            backgroundColor: this.bgColorArr,
            hoverOffset: 4
          },
          {
            label:'Actual' + yLabel,
            data: this.dataSetActual,
            backgroundColor: this.bgColorArrA,
            hoverOffset: 4
          }
        ]
       }
      return obj
    }else if(type == 'Area Chart'){
      obj =  {
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            backgroundColor: this.bgColorArr,
          },
          {
            label:'Actual' + yLabel,
            data: this.dataSetActual,
            backgroundColor: this.bgColorArrA,
          }
        ]
       }
      return obj
    } else {
      obj =  {// values on X-Axis
        labels: this.labelArr,
        datasets: [
          {
            label: yLabel,
            data: this.dataSet,
            backgroundColor: this.bgColorArr
          },
          {
            label: 'Actual' + yLabel ,
            data: this.dataSetActual,
            backgroundColor: this.bgColorArrA
          }
        ]
      }
      return obj
    }

  }

  getChartType(type:any){
    if(type == 'Line Chart'){
      return 'line'
    } else if(type == 'Bar Chart'){
      return 'bar'
    } else if(type == 'Radar Chart'){
      return 'radar'
    } else if(type == 'Pie Chart'){
      
      return 'pie'
    } else if(type == 'Donut Chart'){
      
      return 'doughnut'
    } else if(type == 'Area Chart'){
      
      return 'polarArea'
    } else {
      return 'bar'
    }
  }

  randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

   randomRGB = () => `rgb(${this.randomNum()}, ${this.randomNum()}, ${this.randomNum()})`;

  getLables(xAxisLabel: any, yLabel: any) {
    this.labelArr = []
    this.dataSetActual = []
    this.dataSet = []
    this.bgColorArr = []
    this.bgColorArrA = []
    for (let i = 0; i < this.bookingOrderArr.length; i++) {
      if (xAxisLabel == 'Project') {
        if(this.bookingOrderArr[i].project == undefined || this.bookingOrderArr[i].project == null){
          continue;
        }
        let index = this.labelArr.findIndex(el => el == this.bookingOrderArr[i].project)
        if (index == -1) {
          this.labelArr.push(this.bookingOrderArr[i].project)
          if (yLabel == 'Cost') {
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActualCost))
            this.dataSet.push(Number(this.bookingOrderArr[i].totalCost))
          }
          if (yLabel == 'Price') {
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActual))
            this.dataSet.push(Number(this.bookingOrderArr[i].totalPrice))
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) /24
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24) 
              this.dataSetActual.push(Number(hoursA))
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual.push(Number(hoursA))
            }


          }
          // if(this.bookingOrderArr[i].projectTheme != undefined && this.bookingOrderArr[i].projectTheme != null){
          //   this.bgColorArr.push(this.hex2rgbChart(this.bookingOrderArr[i].projectTheme))
          //   this.bgColorArr.push(this.hex2rgbChart(this.bookingOrderArr[i].projectTheme))
          // } else {
            this.bgColorArr.push(this.randomRGB())
            this.bgColorArrA.push(this.randomRGB())
          // }
          
        } else {
          if (yLabel == 'Cost') {
            this.dataSet[index] = Number(this.bookingOrderArr[i].totalCost) + Number(this.dataSet[index])
            this.dataSetActual[index] = Number(this.bookingOrderArr[i].totalActual) + Number(this.dataSetActual[index])
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / 24
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])

            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }


          }
        }
      }
      if (xAxisLabel == 'Client') {
        if(this.bookingOrderArr[i].client == undefined || this.bookingOrderArr[i].client == null){
          continue;
        }
        let index = this.labelArr.findIndex(el => el == this.bookingOrderArr[i].client)
        if (index == -1) {
          this.labelArr.push(this.bookingOrderArr[i].client)
          if (yLabel == 'Cost') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalCost))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActualCost))
          }
          if (yLabel == 'Price') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalPrice))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActual))
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) /24
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual.push(Number(hoursA))
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSet.push(Number(hoursA))
            }


          }

          this.bgColorArr.push(this.randomRGB())
          this.bgColorArrA.push(this.randomRGB())
        } else {
          if (yLabel == 'Cost') {
            this.dataSet[index] = Number(this.bookingOrderArr[i].totalCost) + Number(this.dataSet[index])
            this.dataSetActual[index] = Number(this.bookingOrderArr[i].totalActual) + Number(this.dataSetActual[index])
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / 24
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }


          }
        }
      }

      if (xAxisLabel == 'Booking') {
        if(this.bookingOrderArr[i].booking == undefined || this.bookingOrderArr[i].booking == null){
          continue;
        }
        let index = this.labelArr.findIndex(el => el == this.bookingOrderArr[i].booking)
        if (index == -1) {
          this.labelArr.push(this.bookingOrderArr[i].booking)
          if (yLabel == 'Cost') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalCost))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActualCost))
          }
          if (yLabel == 'Price') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalPrice))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActual))
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / 24
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual.push(Number(hoursA))
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual.push(Number(hoursA))
            }


          }
          // if(this.bookingOrderArr[i].functionTheme != undefined && this.bookingOrderArr[i].functionTheme != null){
          //   this.bgColorArr.push(this.hex2rgbChart(this.bookingOrderArr[i].functionTheme))
          // } else {
            this.bgColorArr.push(this.randomRGB())
            this.bgColorArrA.push(this.randomRGB())
          // }
        } else {
          if (yLabel == 'Cost') {
            this.dataSet[index] = Number(this.bookingOrderArr[i].totalCost) + Number(this.dataSet[index])
            this.dataSetActual[index] = Number(this.bookingOrderArr[i].totalActual) + Number(this.dataSet[index])
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / 24
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }


          }
        }
      }

      if (xAxisLabel == 'Resource') {
        if(this.bookingOrderArr[i].resources == undefined || this.bookingOrderArr[i].resources == null){
          continue;
        }
        let index = this.labelArr.findIndex(el => el == this.bookingOrderArr[i].resources)
        if (index == -1) {
          this.labelArr.push(this.bookingOrderArr[i].resources)
          if (yLabel == 'Cost') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalCost))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActualCost))
          }
          if (yLabel == 'Price') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalPrice))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActual))
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) /24
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual.push(Number(hoursA))
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual.push(Number(hoursA))
            }


          }
          this.bgColorArr.push(this.randomRGB())
          this.bgColorArrA.push(this.randomRGB())
        } else {
          if (yLabel == 'Cost') {
            this.dataSet[index] = Number(this.bookingOrderArr[i].totalCost) + Number(this.dataSet[index])
            this.dataSetActual[index] = Number(this.bookingOrderArr[i].totalActual) + Number(this.dataSetActual[index])
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) /24
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }


          }
        }
      }
      if (xAxisLabel == 'Account Manager') {
        if(this.bookingOrderArr[i].accountManagerId == undefined || this.bookingOrderArr[i].accountManagerId == null){
          continue;
        }
        let accountManager =  this.returnPersonalName(this.bookingOrderArr[i].accountManagerId)
        let index = this.labelArr.findIndex(el => el == accountManager)
        if (index == -1) {
          this.labelArr.push(accountManager)
          if (yLabel == 'Cost') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalCost))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActualCost))
          }
          if (yLabel == 'Price') {
            this.dataSet.push(Number(this.bookingOrderArr[i].totalPrice))
            this.dataSetActual.push(Number(this.bookingOrderArr[i].totalActual))
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) 
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / 24
              this.dataSetActual.push(Number(hoursA))
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual.push(Number(hoursA))
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet.push(Number(hours))
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual.push(Number(hoursA))
            }


          }
          this.bgColorArr.push(this.randomRGB())
          this.bgColorArrA.push(this.randomRGB())
        } else {
          if (yLabel == 'Cost') {
            this.dataSet[index] = Number(this.bookingOrderArr[i].totalCost) + Number(this.dataSet[index])
            this.dataSetActual[index] = Number(this.bookingOrderArr[i].totalActual) + Number(this.dataSetActual[index])
          }
          if (yLabel == 'Duration') {
            if (this.bookingOrderArr[i].basis == 'Day') {
              let hours = Number(this.bookingOrderArr[i].duration) 
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration)
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Hour') {
              let hours = Number(this.bookingOrderArr[i].duration) / 24
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) /24
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }
            if (this.bookingOrderArr[i].basis == 'Minute') {
              let hours = Number(this.bookingOrderArr[i].duration) / (60 * 24)
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) / (60 * 24)
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }

            if (this.bookingOrderArr[i].basis == 'Week') {
              let hours = Number(this.bookingOrderArr[i].duration) * 7
              this.dataSet[index] = Number(hours) + Number(this.dataSet[index])
              let hoursA = Number(this.bookingOrderArr[i].actualDuration) * 7
              this.dataSetActual[index] = Number(hoursA) + Number(this.dataSetActual[index])
            }


          }
        }
      }


    }

    console.log(this.dataSet)
  }

}