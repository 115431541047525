<div class="row booking-work-order-table">

    <div class="col-lg-12" *ngIf="ViewChart">
        <div *ngIf="blockedPanel1 == true">
            <div class="text-center" >
                <i class="fa fa-spinner fa-pulse fa-2x fa-fw loding"></i>
            </div>
        </div>
        <div *ngIf="blockedPanel1 == false" style="position: relative; height:50vh; width:55vw">    
           <canvas  id="yourCavasId" ></canvas> 
          </div> 
    </div>
     
  <div class="col-lg-12" *ngIf="!ViewChart">
      <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive">
                <table id="table_id" class="table common-Table">
                    <thead>
                        <tr>
                            <th class="cursor-p">Client</th>
                            <th class="cursor-p">Project</th>
                            <th class="cursor-p">Booking ID</th>
                            <th class="cursor-p">Booking</th>
                            <th class="cursor-p">Resource</th>
                            <th class="cursor-p">Kit Name</th>
                            <th class="cursor-p">Account Manager</th>
                            <th class="cursor-p">Date Start</th>
                            <th class="cursor-p">Date End</th>
                            <th class="cursor-p">Qty</th>
                            <th class="cursor-p">Duration</th>
                            <th class="cursor-p">Total Actual Duration</th>
                            <th class="cursor-p">Basis</th>
                            <th class="cursor-p">Total Actual Cost</th>
                            <th class="cursor-p">Total Cost</th>
                            <th class="cursor-p">Total Actual</th>
                            <th class="cursor-p">Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="blockedPanel1 == true">
                          <td colspan="6" >
                              <i class="fa fa-spinner fa-pulse fa-2x fa-fw loding"></i>
                          </td>
                      </tr>
                        <tr *ngFor="let obj of bookingOrderArr">
                            <td>{{obj.client}}</td>
                            <td *ngIf="obj.projectTheme != null" [ngStyle]="{'background-color':obj.projectTheme,'color':obj.color}">{{obj.project}}</td>
                            <td *ngIf="obj.projectTheme == null" >{{obj.project}}</td>
                            <td>{{obj.bookingID}}</td>
                            <td *ngIf="obj.functionTheme != null" [ngStyle]="{'background-color':obj.functionTheme,'color':obj.fnColor}">{{obj.booking}}</td>
                            <td *ngIf="obj.functionTheme == null">{{obj.booking}}</td>
                            <td>{{obj.resources}}</td>
                            <td>{{obj.kitName}}</td>
                            <td *ngIf="obj.accountManagerId == null">{{''}}</td>
                            <td *ngIf="obj.accountManagerId != null">{{returnPersonalName(obj.accountManagerId) }}</td>
                            <td *ngIf="obj?.dateStart == null || obj?.dateStart == ' '">{{''}}</td>
                            <td *ngIf="obj?.dateStart != null && obj?.dateStart != ' '">{{obj?.dateStart | date:'dd/MM/yyyy'}}</td>
                            <td *ngIf="obj?.dateEnd == null || obj?.dateEnd == ' '">{{''}}</td>
                            <td *ngIf="obj?.dateEnd != null && obj?.dateEnd != ' '">{{obj?.dateEnd | date:'dd/MM/yyyy'}}</td>
                            <td>{{obj.qty}}</td>
                            <td>{{obj.duration}}</td>
                            <td>{{obj.actualDuration}}</td>
                            <td>{{obj.basis}}</td>
                            <td>{{obj.totalActualCost}}</td>
                            <td>{{obj.totalCost}}</td>
                            <td>{{obj.totalActual}}</td>
                            <td>{{obj.totalPrice}}</td>
                        </tr>
                        <tr *ngIf="bookingOrderArr.length > 0" style="bottom: 0;">
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td style="font-weight: bolder;" >Sum Total</td>
                            <td ></td>
                            <td ></td>
                            <td colspan="1" style="font-weight: bolder;">{{sumTotalActualCost}}</td>
                            <td colspan="1" style="font-weight: bolder;">{{sumTotalCost}}</td>
                            <td colspan="1" style="font-weight: bolder;">{{sumTotalActual}}</td>
                            <td colspan="1" style="font-weight: bolder;">{{sumTotalPrice}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
      </div>

  </div>
 </div>