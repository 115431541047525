import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SaveEquipmentMilestonesComponent } from 'src/app/configuration-components/save-equipment-milestones/save-equipment-milestones.component';


@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  public PREF_PROJECT_DASHBOARD: string = "project_dashboard";
  public PREF_GOOGLE_SYNC: string = "google_sync";
  public PREF_PROJECT_BOARD: string = "project_board";
  public PREF_WORK_ORDER_BOARD: string = "work_order_board";

  private changes: any = {};
  private prefMap: any = {};
  private prefCtrl: any = {};

  pref: any = {};
  constructor(private httpService: HttpService) {
    this.prefCtrl[this.PREF_PROJECT_DASHBOARD] = {
      validate: this.validateProjectDashboard
    };
    this.prefCtrl[this.PREF_GOOGLE_SYNC] = {
      validate: this.validateGoogleSync
    };
    this.prefCtrl[this.PREF_PROJECT_BOARD] = {
      validate: this.validateProjectBoard
    };
    this.changes[this.PREF_PROJECT_DASHBOARD] =
      this.createChangeSubject();
    this.changes[this.PREF_GOOGLE_SYNC] =
      this.createChangeSubject();
    this.changes[this.PREF_PROJECT_BOARD] =
      this.createChangeSubject();
  }

  private createChangeSubject() {
    const subject = new Subject<any>();
    subject.pipe(debounceTime(5000))
      .subscribe(name => this.sendToServer(name));
    return subject;
  }

  private validateProjectDashboard(comp: UserPreferencesService) {
    const o = (comp.PREF_PROJECT_DASHBOARD in comp.prefMap) ? 
      comp.prefMap[comp.PREF_PROJECT_DASHBOARD]: {};

    if ('colSpec' in o) {} else {
      if ('id' in o) {} else {o['id'] = 0;}
      o['colSpec'] = [{
        categoryId: -1,
        columns: {} // Category id mapped here {id: {cols: [... colSpec ...] }}
      }];
    }

    // Update anyway
    comp.prefMap[comp.PREF_PROJECT_DASHBOARD] = o;
  }

  private validateProjectBoard(comp: UserPreferencesService) {
    const o = (comp.PREF_PROJECT_BOARD in comp.prefMap) ? 
      comp.prefMap[comp.PREF_PROJECT_BOARD]: {};

    if ('colSpec' in o) {} else {
      if ('id' in o) {} else {o['id'] = 0;}
      o['colSpec'] = [{
        categoryId: -1,
        columns: {} // Category id mapped here {id: {cols: [... colSpec ...] }}
      }];
    }

    // Update anyway
    comp.prefMap[comp.PREF_PROJECT_BOARD] = o;
  }

  private validateGoogleSync(comp: UserPreferencesService) {
    const o = (comp.PREF_GOOGLE_SYNC in comp.prefMap) ? 
      comp.prefMap[comp.PREF_GOOGLE_SYNC]: {};

    if ('id' in o) {} else {o.id = 0;}
    if ('enabled' in o) {} else {o.enabled = false;}
    if ('email' in o) {} else {o.email = '';}
    if ('lastNotificationId' in o) {} else {o.lastNotificationId = 0;}

    // Update anyway
    comp.prefMap[comp.PREF_GOOGLE_SYNC] = o;
  }



  private validate() {
    for(let k in this.prefCtrl) {
      this.prefCtrl[k].validate(this);
    }
  }

  getPreference(name: string) {
    const that = this;
    // if no local copy
		const promise = new Promise(function(resolve, reject) {
      if (name in that.prefCtrl) {} else {
        reject(`Invalid preference ${name}`);
        return;
      }

      that.httpService.callApi('getUserPreferences', {}).subscribe(resp => {
        resp.forEach(v => {
          try {
            that.prefMap[v.name] = JSON.parse(v.value);
          } catch(ex) {
            that.prefMap[v.name] = {}
          }
          that.prefMap[v.name].id = v.id;
        });
        that.validate();
        // To give a copy
        resolve(JSON.parse(JSON.stringify(that.prefMap[name])));
        
		  }, err => {reject(err)});
		});
		return promise;
  }
  
  
  
  setPreference(name: string, value: any) {
    if (name in this.prefCtrl) {} else {
      alert(`Invalid preference ${name}`);
      return;
    }
    const copy = JSON.parse(JSON.stringify(value));
    copy.id = this.prefMap[name].id;
    this.prefMap[name] = copy;
    
    // console.log('Wait', name);
    this.changes[name].next(name);
  }

  private sendToServer(name: string) {
    const data = {
      id: this.prefMap[name].id,
      name: name,
      value: JSON.stringify(this.prefMap[name])
    }
    // console.log('Send to server', name, data);
    this.httpService.callApi('setUserPreferences', {body: data})
      .subscribe(resp => {
        
        this.prefMap[name].id = resp.id;
        // console.log('SAVE', resp, this.prefMap[name]);
      })
  }

}
