<app-single-header></app-single-header>
<div class="welcome-screen">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="card border-0">
                    <div class="card-body px-0">
                        <div class="box border-0">
                            <h2 class="title font-roboto-cad">Create a project</h2>
                            <div class="box-thumbnail">
                                <img src=".../../assets/img/create-project.png" alt="">
                            </div>
                            <div class="info font-roboto-cad font-14 mt-2">
                                Build a quotation find suitable resource and talent
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card border-0">
                    <div class="card-body px-0">
                        <div class="box border-0">
                            <h2 class="title font-roboto-cad">{{'Schedule' | translate}} {{'resources' | translate}}</h2>
                            <div class="box-thumbnail">
                                <img src="../../assets/img/schedule.png" alt="">
                            </div>
                            <div class="info font-roboto-cad font-14 mt-2">
                                Manage Staff and Resources with ad-hoc administartion contact form
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card border-0">
                    <div class="card-body px-0">
                        <div class="box border-0">
                            <h2 class="title font-roboto-cad">Collaborate and deliver</h2>
                            <div class="box-thumbnail">
                                <img src="../../assets/img/collabrate.png" alt="">
                            </div>
                            <div class="info font-roboto-cad font-14 mt-2">
                                Create invoice and get financial report to drive your business
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="basic-form-footer" style="position: relative; z-index: 100;">
            <button class="btn btn-account btn-lg mb-15 font-15" type="button" (click)="goSignin()">{{'Continue' | translate}}</button>
        </div>
        
        <div class="area" >
            <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </div>
</div>