import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlsService } from "../urls.service";

@Injectable({
  providedIn: "root",
})
export class ProjectListService {
  constructor(private http: HttpClient, private url: UrlsService) {}

  
  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  getNameBatches(formData) {
    return this.http.post(`${this.url.urlString}budget/non-archived/client`, formData).toPromise();
  }

  getAllTemplate(formData) {
    return this.http.post(`${this.url.urlString}budget/getAllTemplate`, formData).toPromise();
  }

  getAllbookings(formData) {
    return this.http.post(`${this.url.urlString}budget/non-archived/getAllbookings`, formData).toPromise();
  }

  getAllMilestones(formData) {
    return this.http.post(`${this.url.urlString}budget/milestoneGetAll`, formData).toPromise();
  }

  getAllDescription(formData) {
    return this.http.post(`${this.url.urlString}budget/description`, formData).toPromise();
  }

  getCompanyForProject(formData) {
    return this.http.post(`${this.url.urlString}budget/non-archived/getCompanyForProject`, formData).toPromise();
  }

  getCategoryForProject(formData) {
    return this.http.post(`${this.url.urlString}budget/non-archived/getAllCategory`, formData).toPromise();
  }

  getShowForProject(formData) {
    return this.http.post(`${this.url.urlString}budget/non-archived/getAllShow`, formData).toPromise();
  }

  getProjectsForUserDefinedPage() {
    return this.http.get(`${this.url.urlString}project/getProjectsForUserDefinedPageLimited`).toPromise();
  }

  getAllNonArchivedProject(formData:any) {
    return this.http.get(`${this.url.urlString}budget/non-archived/clientProject/filter?${this.serialize(formData)}`).toPromise();
  }

  getAllNonArchivedSearch(formData:any) {
    return this.http.get(`${this.url.urlString}budget/Done/search?${this.serialize(formData)}`).toPromise();
  }

  getAllSearchProject(formData:any) {
    return this.http.get(`${this.url.urlString}budget/search?${this.serialize(formData)}`).toPromise();
  }

  getAllSearchProjectToDo(formData:any) {
    return this.http.get(`${this.url.urlString}budget/ToDo/search?${this.serialize(formData)}`).toPromise();
  }


  getAllNonArchivedProjectToDo(formData:any) {
    return this.http.get(`${this.url.urlString}budget/non-archived/clientProject/ToDo/filter?${this.serialize(formData)}`).toPromise();
  }


  getAllNonArchivedProjectDone(formData:any) {
    return this.http.get(`${this.url.urlString}budget/non-archived/clientProject/Done/filter?${this.serialize(formData)}`).toPromise();
  }

  getAllSearchProjectDone(formData:any) {
    return this.http.get(`${this.url.urlString}budget/Done/search?${this.serialize(formData)}`).toPromise();
  }

  getRecentNonArchivedProject() {
    return this.http.get(`${this.url.urlString}budget/non-archived/recentproject/0`).toPromise();
  }

  getAllProjectDataName() {
    return this.http.get(`${this.url.urlString}budget/non-archived/ProjectName`).toPromise();
  }

  getAllRundown(formData:any) {
    return this.http.get(`${this.url.urlString}project-board/rundown?${this.serialize(formData)}`).toPromise();
  }

  getAllTransmission(formData:any) {
    return this.http.get(`${this.url.urlString}dashboard/transmission-dashboard-by-filter?${this.serialize(formData)}`).toPromise();
  }

  deleteProject(projectId:any) {
    return this.http.delete(`${this.url.urlString}budget/${projectId}`).toPromise();
  }

  saveproject(formData:any){
    return this.http.post(`${this.url.urlString}budget/save-alhpa-budget`,formData).toPromise();
  }
  
  saveprojectBudget(formData:any){
    return this.http.post(`${this.url.urlString}budget`,formData).toPromise();
  }

  saveLine(formData:any,id:any){
    return this.http.post(`${this.url.urlString}budget/booking/${id}`,formData).toPromise();
  }

  bookingRequest(formData:any){
    return this.http.post(`${this.url.urlString}project/planning/booking-request`,formData).toPromise();
  }

  getAllProjectWithLine(formData:any){
    return this.http.get(`${this.url.urlString}project-board/getAllProject?${this.serialize(formData)}`).toPromise();
  }

  getAllProjectWithSearch(formData:any){
    return this.http.get(`${this.url.urlString}project-board/search?${this.serialize(formData)}`).toPromise();
  }

  getcolumnsData(formData:any){
    return this.http.get(`${this.url.urlString}project-board/get/${formData}`).toPromise();
  }

  createColumn(formData:any){
    return this.http.post(`${this.url.urlString}project-board/create`,formData,{responseType: 'text'}).toPromise();
  }

  resetColumn(formData:any){
    return this.http.get(`${this.url.urlString}project-board/resetProjectDesk/${formData}`).toPromise();

  }

  changeColumnIndex(userId:any,previousIndex:any,currentIndex:any){
    return this.http.post(`${this.url.urlString}project-board/dropAndDrag/${userId}/${previousIndex}/${currentIndex}`,{}).toPromise();
  }

  changeColumnIndexEquipment(userId:any,previousIndex:any,currentIndex:any){
    return this.http.post(`${this.url.urlString}equipment/dropAndDrag/${userId}/${previousIndex}/${currentIndex}`,{}).toPromise();
  }

  changeColumnIndexStaff(userId:any,previousIndex:any,currentIndex:any){
    return this.http.post(`${this.url.urlString}staff/dropAndDrag/${userId}/${previousIndex}/${currentIndex}`,{}).toPromise();
  }

  deleteColumn(formData:any){
    return this.http.delete(`${this.url.urlString}project-board/deletebyId/${formData}`,{responseType: 'text'}).toPromise();
  }

  getEventByProject(formData:any,fromBooking:any){
    return this.http.get(`${this.url.urlString}project-planning/booking/${formData}/${fromBooking}`).toPromise();
  }

  addLineToProject(formData:any,projectId:any){
    return this.http.post(`${this.url.urlString}project/booking/${projectId}`,formData).toPromise();
  }

  addOrUpdateLicenseValue(formData:any){
    return this.http.post(`${this.url.urlString}booking/update-service`,formData).toPromise();
  }

  removeLicense(bookingId:any){
    return this.http.post(`${this.url.urlString}booking/remove-service/${bookingId}`,{}).toPromise();
  }
  
  addPlanningTable(formData:any){
    return this.http.post(`${this.url.urlString}project-planning/event`,formData).toPromise();

  }

  addTemplate(formData:any){
    return this.http.post(`${this.url.urlString}notification/template/`,formData).toPromise();
  }

  getTemplate(userId:any){
    return this.http.get(`${this.url.urlString}notification/template/${userId}`).toPromise();
  }

  deleteTemplate(formData:any){
    return this.http.delete(`${this.url.urlString}notification/template/${formData}`).toPromise();
  }

  getOrderByProject(projectId:any){
    return this.http.get(`${this.url.urlString}order/by-project/${projectId}`).toPromise()
  }

  saveProjectComment(projectId:any,formData:any){
    return this.http.post(`${this.url.urlString}project/comment/${projectId}`,formData).toPromise();
  }

  getProjectComments(projectId:any){
    return this.http.get(`${this.url.urlString}project/comment/${projectId}`).toPromise();
  }

  deleteComment(projectId:any,commentId:any){
    return this.http.delete(`${this.url.urlString}project/comment/${projectId}/${commentId}`,{responseType: 'text'}).toPromise();
  }

  saveService(formData:any){
    return this.http.post(`${this.url.urlString}configuration/servicefunction`,formData).toPromise();
  }

  deleteService(serviceId){
    return  this.http.delete(`${this.url.urlString}configuration/servicefunction/${serviceId}`,{responseType: 'text'}).toPromise();
  }

  getEventByProjectRequest(projectId,fromBooking){
    return this.http.get(`${this.url.urlString}project-planning/getEventByProject/${projectId}/${fromBooking}`).toPromise()
  }
  getEventByProjectAndline(projectId,lineId){
    return this.http.get(`${this.url.urlString}budget/booking/basic/${projectId}/${lineId}`).toPromise()
  }

  addLocationFN(formData:any,userId:any){
    return this.http.post(`${this.url.urlString}personel/location/${userId}`,formData).toPromise();
  }

  addEquipmentFN(formData:any,userId:any){
    return this.http.post(`${this.url.urlString}personel/equipment/${userId}`,formData).toPromise();
  }

  getLocationFN(userId:any){
    return this.http.get(`${this.url.urlString}personel/location/${userId}`).toPromise();
  }

  getEquipmentFN(userId:any){
    return this.http.get(`${this.url.urlString}personel/equipment/${userId}`).toPromise();
  }

  deleteLocation(locationId:any,userId:any){
    return  this.http.delete(`${this.url.urlString}personel/location/${userId}/${locationId}`,{responseType: 'text'}).toPromise();
  }

  deleteEquipment(equipmentId:any,userId:any){
    return  this.http.delete(`${this.url.urlString}personel/equipment/${userId}/${equipmentId}`,{responseType: 'text'}).toPromise();
  }

  setFunctionForRundown(formData:any){
    return this.http.post(`${this.url.urlString}project-board/rundownfunction`,formData).toPromise();
  }

  getFunctionForRundown(){
    let userId = sessionStorage.getItem('userId');
    return this.http.get(`${this.url.urlString}project-board/rundownfunction/${userId}`).toPromise();

  }

  getBookingOrderData(dateObj:any){
    return this.http.get(`${this.url.urlString}project/exportBillingReportPDF/export?${this.serialize(dateObj)}`).toPromise();
  }

  getBookingPersonalOrderById(dateObj:any){
    return this.http.get(`${this.url.urlString}project/exportPersonnelBillingReportByProject/export?${this.serialize(dateObj)}`).toPromise();
  }
  
  getBookingPersonalData(dateObj:any){
    return this.http.get(`${this.url.urlString}project/exportPersonnelBillingReportPDF/export?${this.serialize(dateObj)}`).toPromise();
  }

  getBookingPersonalDownload(dateObj:any){
    return this.http.get(`${this.url.urlString}project/exportPersonnelBillingReportDownload/export?${this.serialize(dateObj)}`).toPromise();
  }

  getBookingResourceDownload(dateObj:any){
    return this.http.get(`${this.url.urlString}project/exportResourceBillingReportDownload/export?${this.serialize(dateObj)}`).toPromise();
  }

  calldataSheetID(dateObj:any){
    return this.http.post(`${this.url.urlString}booking//line/saveactualised?${this.serialize(dateObj)}`,{}).toPromise();
  }

  getPersonalDuty(dateObj:any){
    return this.http.get(`${this.url.urlString}project/rosterReport/export?${this.serialize(dateObj)}`).toPromise();
  }

  getEventsReport(dateObj:any){
    return this.http.get(`${this.url.urlString}event/schedulerReport/export?${this.serialize(dateObj)}`).toPromise();
  }

  getMeetingReport(dateObj:any){
    return this.http.get(`${this.url.urlString}project/meetingReport/export?${this.serialize(dateObj)}`).toPromise();
  }

  getSkillReport(dateObj:any){
    return this.http.get(`${this.url.urlString}resource/assigned/staffResources/skill/${dateObj.startDate}/${dateObj.endDate}`).toPromise();
  }

  getAllOrder(projectid:any,lineid:any){
    return this.http.get(`${this.url.urlString}workorder/${projectid}/${lineid}`).toPromise();
  }
  
  getdayPlusData(id:any){
    return this.http.get(`${this.url.urlString}event/day-pluse/${id}`).toPromise();
  }

  deleteDocument(id:any){
    return this.http.delete(`${this.url.urlString}staff/document/${id}`,{responseType: 'text'}).toPromise();
  }

  setStaffDataDoc(id:any,formData:any){
    return this.http.post(`${this.url.urlString}staff/document/${id}`,formData).toPromise();
  }

  saveIncidentReport(formData:any){
    return this.http.post(`${this.url.urlString}project-note/incident`,formData).toPromise();
  }

  getIncidentLine(projectId:any){
    return this.http.get(`${this.url.urlString}project-note/getIncidentByProject/${projectId}`).toPromise();
  }

  setIncidentLine(formData:any){
    return this.http.post(`${this.url.urlString}project-note/incident`,formData).toPromise();
  }

  pasteTableData(tableId,formData:any){
    return this.http.post(`${this.url.urlString}project-planning/copyPlanningEvent/${tableId}`,formData).toPromise();
  }

  pasteTableDataRequest(tableId,formData:any){
    return this.http.post(`${this.url.urlString}project-planning/copyPlanningEventRequest/${tableId}`,formData).toPromise();
  }
  pasteTableDataRequestShow(tableId,formData:any){
    return this.http.post(`${this.url.urlString}project-planning/copyShowPlanningEventRequest/${tableId}`,formData).toPromise();
  }

  saveExchangeRate(formData:any){
    return this.http.post(`${this.url.urlString}currencyExchange`,formData).toPromise();
  }

  saveRepeatProject(formData:any){
    return this.http.post(`${this.url.urlString}repeat/project`,formData).toPromise();
  }

  getAllExchangeRate(Id:any){
    return this.http.get(`${this.url.urlString}currencyExchange/project/${Id}`).toPromise();
  }

  deleteExchangeRate(Id:any){
    return this.http.delete(`${this.url.urlString}currencyExchange/${Id}`).toPromise();
  }

  setEquipmentMilestone(id:any,formData:any){
    return this.http.post(`${this.url.urlString}equipment/milestone/`,formData).toPromise();
  }

  getWorkOrder(formData:any){
    return this.http.get(`${this.url.urlString}dashboard/process-work-order?${this.serialize(formData)}`).toPromise();
  }

  getTravelOrder(formData:any){
    return this.http.get(`${this.url.urlString}dashboard/process-travel-order?${this.serialize(formData)}`).toPromise();

  }

  getMeetingLines(meetingId:any){
    return this.http.get(`${this.url.urlString}project-note/meetingLine/${meetingId}`).toPromise();
  }

  getMeetingAttendees(meetingId:any){
    return this.http.get(`${this.url.urlString}project-note/getMeetingAttendeesByMeetingId/${meetingId}`).toPromise();
  }

  getRequestEstimate(formData:any){
    return this.http.post(`${this.url.urlString}project/update-project-estimate?${this.serialize(formData)}`,{}).toPromise();
  }

  notifyRequestTable(projectId:any,tableId:any){
    return this.http.post(`${this.url.urlString}notification/notify-request/${projectId}/${tableId}`,{}).toPromise();
  }

  notifyRequestAll(projectId:any){
    return this.http.post(`${this.url.urlString}notification/notify-request-all/${projectId}`,{}).toPromise();
  }

  cloneEquipment(formData:any){
    return this.http.post(`${this.url.urlString}equipment/clone`,formData).toPromise();
  }

  createtags(formData:any){
    return this.http.post(`${this.url.urlString}globalkeywords/${formData.resourceType}`,formData).toPromise();
  }

  linkTagsTOProject(formData:any){
    return this.http.post(`${this.url.urlString}project/set-record-tags`,formData).toPromise();
  }

  linkTagsTOStaff(formData:any){
    return this.http.post(`${this.url.urlString}staff/set-tags`,formData).toPromise();
  }

  skillAndLangTOStaff(formData:any){
    return this.http.post(`${this.url.urlString}staff/update-skill-lang`,formData).toPromise();
  }

  getAllKeywordsTags(){
    return this.http.get(`${this.url.urlString}globalkeywords`).toPromise();
  }

  getAllTaskProject(projectId:any){
    return this.http.get(`${this.url.urlString}project-note/bookingTaskLine/record/${projectId}`).toPromise();
  }

  getAllTaskByLines(projectId:any,bookingId:any){
    return this.http.get(`${this.url.urlString}project-note/bookingTaskLine/${projectId}/${bookingId}`).toPromise();
  }

  createTaskProject(formData:any){
    return this.http.post(`${this.url.urlString}project-note/bookingTaskLine`,formData).toPromise();
  }

  notifyTaskProject(formData:any){
    return this.http.post(`${this.url.urlString}project-note/notify/task`,formData).toPromise();
  }

  getAllTaskDashBoard(formData:any){
    return this.http.get(`${this.url.urlString}project-note/bookingTaskLine?${this.serialize(formData)}`).toPromise();
  }

  getAllTaskDashBoardForUser(staffId:any){
    return this.http.get(`${this.url.urlString}project-note/bookingTaskLine/person/${staffId}`).toPromise();
  }

  deletetask(formData:any){
    return this.http.delete(`${this.url.urlString}project-note/bookingTaskLine/${formData}`,{responseType: 'text'}).toPromise()
  }




}
