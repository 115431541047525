<!-- SIGNIN PAGE -->
<div class="signin" (window:resize)="onResize($event)" [style.minHeight.px]="myInnerHeight" style="min-height: 754px;">
  <div class="inner-page">
      <h3 class="title3">Sign In</h3>

      <div class="page-logo">
          <img src="assets/img/signin-icon-white.png" alt="" class="logo1">
          <img src="assets/img/Teamium-white.png" alt="" class="logo2">
          <p class="font-14">PRODUCTION PLATFORM</p>
      </div>

          <div class="basic-form">
              <div class="form-control-row">
                  <span class="icon"><i class="fa fa-user"></i></span>
                  <input type="text" name="username" class="form-control" disabled>
                  <label>User Name*</label>
              </div>
              <label for="" class="mt-10" style="color: #5cc8e3; font-size: 16px;">Logged in Okta</label>
          </div>


          <div class="basic-form-footer">
              <button class="btn btn-lg mb-15" type="submit">Start</button>
          </div>
  </div>
</div>