<!-- <div class="sidebar">
    <ul id="dashboard-panel">
        <li class="" *ngFor="let item of menu" (click)='changeMenuItem(item)' [ngClass]='{active:activeMenu==item}' [hidden]='!item.show'>
            <a href="javascript:void(0)">
                <span class="leftIcon tooltipDiv" *ngIf="item.img" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">                    
                    <img [src]="item.img" class="whiteIcon" alt="">
                    <img [src]="item.activeImg" class="ActiveIcon" alt="">
                    <span class="tooltiptext">{{item.title}}</span>
                </span>
                <i class="leftIcon" [ngClass]="item.icon" aria-hidden="true" *ngIf="item.icon"></i>
                <span class="title">{{item.title  | translate }}</span>
            </a>
        </li>
    </ul>

    <div class="subMenu" *ngFor="let item of menu">
        <div *ngIf="activeMenu===item" >
            <div class="menuTitle">{{item.title | translate}}</div> 
            <div class="list">
                <p *ngFor="let subMenu of item.children"  [hidden]='!subMenu.show' [ngClass]="{'active':currentUrl == subMenu.link,'disable-anchor': subMenu?.disabled == true }">
                    <a href="javascript:void(0)" (click)="navigate(subMenu)">{{subMenu.title | translate}}
                    </a>
                </p>
            </div>
        </div>
    </div>

    <div >
        {{'inside' | translate}}
        <div class="innerSubMenu-Sec active" [ngClass]="{'active':activeSubMenu}">
            <div class="innerSubMenu">
                <div>
                    <div class="menuTitle">
                        <a class="backSubMenu" href="javascript:void(0);" (click)="navigate(activeSubMenu)">
                            <i class="fa fa-arrow-left"></i>
                            <span>{{activeSubMenu.title | translate}}</span>
                        </a>
                    </div>
                    <ul class="list">
                        <li class="p-Act" *ngFor="let subMenu of activeSubMenu.children" [ngClass]="innerCurrentUrl==subMenu.link?'active':''">
                            <a href="javascript:void(0)" (click)="navigate(subMenu)">{{subMenu.title | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- 
  <div class="sidebar">
    <div class="logo-details">
      <i class='bx bxl-c-plus-plus icon'></i>
        <div class="logo_name">CodingLab</div>
        <i class='bx bx-menu' id="btn" ></i>
    </div>
    <ul class="nav-list">
      <li>
          <i class='bx bx-search' ></i>
         <input type="text" placeholder="Search...">
         <span class="tooltip">Search</span>
      </li>
      <li  *ngFor="let item of menu" (click)='changeMenuItem(item)'>
        <a href="#">
          <i [ngClass]="item.icon" aria-hidden="true" *ngIf="item.icon"></i>
          <span class="links_name">{{item.title  | translate }}</span>
          <span class="tooltip">{{item.title  | translate }}</span>
        </a>
      </li>
  
     <li class="profile">
         <div class="profile-details">
           <img src="profile.jpg" alt="profileImg">
           <div class="name_job">
             <div class="name">Prem Shahi</div>
             <div class="job">Web designer</div>
           </div>
         </div>
         <i class='bx bx-log-out' id="log_out" ></i>
     </li>
    </ul>
  </div> -->

  <div class="sidebar">
  
    <div class="profile-details" [matMenuTriggerFor]="profileDropdown">
        <div class="profile-content" *ngIf="pictureUrl && loggedInUserDetails != null" title="{{loggedInUserDetails?.firstName}}&nbsp;{{loggedInUserDetails?.lastName}}">
          <img [src]="pictureUrl" alt="profileImg">
        </div>
        <div class="profile-content"  *ngIf="!pictureUrl && loggedInUserDetails != undefined && loggedInUserDetails != null " title="{{loggedInUserDetails?.firstName}}&nbsp;{{loggedInUserDetails?.lastName}}">
           <span [ngStyle]="{'background-color':loggedInUserDetails?.userSettingDTO.staffTheme, 'color':loggedInUserDetails?.userSettingDTO.staffTheme == '#ffffff' ? '#007ebe':'#ffffff'}">
               {{getShortName(loggedInUserDetails?.firstName)}}{{getShortName(loggedInUserDetails?.lastName)}}
           </span>
        </div>
      </div>
      <mat-menu #profileDropdown="matMenu" xPosition="after" >
        <button mat-menu-item class="d-flex justify-content-evenly"  (click)="openProfile()"  > 
            <span style="width: 30%;">
                <i class="fa fa-user" aria-hidden="true" ></i>
            </span>
            <span style="width: 70%;">
                {{'View profile'|translate}}
            </span>
        </button>
     
        <button mat-menu-item class="d-flex justify-content-between" *ngIf="loggedInUserDetails?.darkMode" (click)="changeTheme(false)"> 
            <span style="width: 30%;">
                <i   class="fa fa-sun"></i>
            </span>
            <span style="width: 70%;">
                {{'Light Theme'|translate}}
            </span>
        </button>
        <button mat-menu-item class="d-flex justify-content-between" (click)="changeTheme(true)" *ngIf="!loggedInUserDetails?.darkMode"> 
            <span style="width: 30%;">
                <i   class="fa fa-moon"></i>
            </span>
            <span style="width: 70%;">
                {{'Dark Theme'|translate}}
            </span>
        </button>
        <button mat-menu-item class="d-flex justify-content-between" (click)="logout()"> 
            <span style="width: 30%;">
                <i class="fa fa-power-off"></i>
            </span>
            <span style="width: 70%;">
                {{'Log Out'|translate}}
            </span>
        </button>
    
      </mat-menu>
   
    <ul class="nav-links">
      <li  *ngFor="let item of menu" [ngClass]='{active:activeMenu==item}' (click)='changeMenuItem(item)' [hidden]='!item.show'>
        <div class="iocn-link">
          <a href="javascript:void(0)">
            <i  [ngClass]="item.icon" aria-hidden="true" *ngIf="item.icon" ></i>
            <span class="link_name">{{item.title  | translate }}</span>
          </a>
        </div>
       
        <ul class="sub-menu"  >
          <li style="box-shadow: none;"><a class="link_name" href="javascript:void(0)" (click)='changeMenuItem(item)'>{{item.title  | translate }}</a></li>
          <li style="border-radius: 8px; box-shadow: none;"  *ngFor="let subMenu of item.children"    [hidden]='!subMenu.show'>
            <ng-container *ngIf="item.title == 'Schedule' || item.title == 'Dashboard' || item.title == 'Settings' ">
                <a [ngClass]="{'disable-anchor': subMenu?.disabled == true }" (click)="navigate(subMenu);stopPropagation($event)" href="javascript:void(0)">{{subMenu.title | translate}}</a>

            </ng-container>
            <ng-container *ngIf="!(item.title == 'Schedule' || item.title == 'Dashboard' || item.title == 'Settings') && activeMenu==item">
                <a [ngClass]="{'disable-anchor': subMenu?.disabled == true }" (click)="navigate(subMenu);stopPropagation($event)" href="javascript:void(0)">{{subMenu.title | translate}}</a>

            </ng-container>
        </li>
        </ul>
      </li>
 
      <li>
 
  </li>
</ul>
  </div>

