import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';  
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';



import 'chart.js';
import { charts } from 'highcharts';
import { ReportChartsService } from '../report-charts.service';
import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'app-projects-actual-per-function',
  templateUrl: './projects-actual-per-function.component.html',
  styleUrls: ['./projects-actual-per-function.component.scss'],
  providers: [ReportChartsService]
})
export class ProjectsActualPerFunctionComponent implements OnInit {
  x:any;
  
  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  //       // We use these empty structures as placeholders for dynamic theming.
  //       scales: {
  //         yAxes: [
  //          {
  //              display: true,
  //              ticks: {
  //                fontSize: 8,
  //                callback: value => this.abbreviateNumber(value)

  //              }
  //          }
  //        ],
  //        xAxes: [
  //         {
  //             display: true,
  //             ticks: {
  //               callback: value => _.truncate(value, { length: 5 })
  //             }
  //         }
  //       ]
  //      },
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //       font: {
  //         size: 0,
  //       }
  //     }
  //   }
  // };
  // public barChartColors: Color[] = [
  //   { backgroundColor: '#f6ae2d' }
  //   ]
  // public barChartOptionsModal: ChartOptions = {
  //   responsive: true,
  //       // We use these empty structures as placeholders for dynamic theming.
  //       scales: {
  //         yAxes: [
  //          {
  //              display: true,
  //              ticks: {
  //                fontSize: 16,
  //                callback: value => this.abbreviateNumber(value)

  //              }
  //          }
  //        ]  
  //      },
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //       font: {
  //         size: 12,
  //       }
  //     }
  //   }
  // };

  // public barChartLabels: Label[] = ['Studio', 'Camera', 'Lightiing', 'Cameraram', 'Sound Eng', 'Edit suite', 'GFX','Editor','DA'];

 
  // public barChartType: ChartType = 'bar';
  // public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];
  // public barChartData: ChartDataSets[] = [
  //   { data: [120000, 1400000, 12000000, 300000, 0, 0, 0], label: 'Series A' }
  // ];
  // public hbarChartOptions: ChartOptions = {
  //   responsive: true,
  //   // We use these empty structures as placeholders for dynamic theming.
  //   scales: { xAxes: [{}], yAxes: [{}] },
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //       font: {
  //         size: 0,
  //       }
  //     }
  //   }
  // };
  // public hbarChartLabels: Label[] = ['Studio', 'Camera', 'Lightiing', 'Cameraram', 'Sound Eng', 'Edit suite', 'GFX','Editor','DA'];

  // public hbarChartType: ChartType = 'horizontalBar';
  // public hbarChartLegend = true;
  // public hbarChartPlugins = [pluginDataLabels];

  // public hbarChartData: ChartDataSets[] = [
  //   { data: [120000, 1400000, 12000000, 300000, 0, 0, 0], label: 'Series A' }
  // ];
 
  // public doughnutChartLabels: Label[] = ['a', 'b'];
  // public doughnutChartData: MultiDataSet = [
  //   [83, 17],
 
  // ];
  // public doughnutChartType: ChartType = 'doughnut';

  // public pieChartOptions: ChartOptions = {
  //   responsive: true,
  //   legend: {
  //     display: false,
  //   },
  //   plugins: {
  //     datalabels: {
  //       formatter: (value, ctx) => {
  //         const label = ctx.chart.data.labels[ctx.dataIndex];
  //         return label;
  //       },
  //     },
  //   }
  // };
  // public pieChartOptionsModal: ChartOptions = {
  //   responsive: true,
  //   legend: {
  //     display: true,
  //   },
  //   plugins: {
  //     datalabels: {
  //       formatter: (value, ctx) => {
  //         const label = ctx.chart.data.labels[ctx.dataIndex];
  //         return label;
  //       },
  //     },
  //   }
  // };
  // public pieChartLabels: Label[] = [['1'], ['2'], ['3'],['4']];
  // public pieChartData: number[] = [47, 1, 5,47];
  // public pieChartType: ChartType = 'pie';
  // public pieChartLegend = true;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['#05668d', '#427aa1', '#ebf2fa','#679436','#a5be00'],
    },
  ];
  budgetAmountSpentpieChartLabels=[];
  budgetAmountSpentbarChartLabels=[];
  budgetAmountSpentbarChartValues=[];
  amountSpentPerMonthToDateValues=[];
  percentBudgetValues=[]
  public budgetAmountSpentbarChartData: ChartDataSets[] = [
    { data: this.budgetAmountSpentbarChartValues, label: 'Amount Spent' }
  ];
  public amountSpentPerMonthToDateData: ChartDataSets[] = [
    { data: this.budgetAmountSpentbarChartValues, label: 'Amount Spent per month to date' }
  ];
   constructor(private reportChartService:ReportChartsService) {
   }

  ngOnInit() {
    
    this.reportChartService.getData().subscribe(data=>{
      data.forEach(x => {  
      this.budgetAmountSpentbarChartLabels.push(x.functionName);  
      this.budgetAmountSpentbarChartValues.push(Math.round(x.amountSpentToDate)); 
      this.amountSpentPerMonthToDateValues.push(Math.round(x.amountSpentToDate));
      if(x.budgetPercent>5.0){
        this.budgetAmountSpentpieChartLabels.push([x.functionName]);  
        this.percentBudgetValues.push(Math.round(x.budgetPercent))
        console.log(x.budgetPercent)
      }
      
      
    });
    for(let i=0;i<this.percentBudgetValues.length+1;i++){
      this.pieChartColors[0].backgroundColor.push('#'+Math.floor(Math.random()*16777215).toString(16));
              }

    var sum = this.percentBudgetValues.reduce(function(a, b) { return a + b; }, 0);
    sum=100-sum;
    console.log(sum)
    this.budgetAmountSpentpieChartLabels.push(['others']);    
    this.percentBudgetValues.push(sum)
    console.log(this.budgetAmountSpentpieChartLabels)
    console.log(this.percentBudgetValues)

  })

}
 chartClicked(ev:any): void {
console.log("hello")
}

public abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
      let suffixes = ["", "k", "m", "b","t"];
      let suffixNum = Math.floor( (""+value).length/3 );
      let shortValue;
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

}