<!--     Spinner component-->
<app-spinner [blockedPanel]='blockedPanel'></app-spinner>
<!-- SIGNIN PAGE -->
<div class="container-fluid signin px-0" (window:resize)="onResize($event)" [style.minHeight.px]="myInnerHeight" *ngIf="!isMailSent">
    <div id="rock1"></div>
    <div id="rock2"></div>
    <div id="rock3"></div>
    <div id="rock4"></div>
    <div id="rock5"></div>
    <div id="rock6"></div>
    <div id="rock7"></div>
    <div id="rock8"></div>
    <div id="rock9"></div>
    <div id="rock10"></div>
    <div id="rock11"></div>
    <div id="rock12"></div>
    <div id="rock13"></div>
    <div id="rock14"></div>
    <div id="rock15"></div>
    <div id="rock16"></div>
    <div class="login-box row mx-0 font-roboto-cad">
        <div class="col-xl-6 mx-auto">
            <h3 class="title3 text-white text-center font-20">{{'Forgot Password' | translate}}</h3>
            <div class="row mx-auto w-100 my-4">
                <img src="assets/img/signin-icon-white.png" alt="" class="logo1 mx-auto">
            </div>
            <div class="row mx-auto mt-4">
                <img src="assets/img/Teamium-white.png" alt="" class="img-fluid logo2 mx-auto">
                <p class="font-15 w-100 text-center text-white mt-2">PRODUCTION PLATFORM</p>
            </div>
        </div>

        <form [formGroup]="forgotPasswordForm" (submit)="sendMail()" class="w-100" style="display: grid;">
            <p class="font-15 font-roboto-cad text-white mb-0 text-center mt-3 mb-5">Enter your email address and we will send you a link to reset your password</p>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mx-auto">
                <div class="row mx-0 input-group">
                    <div class="form-group w-100" [ngClass]="{'valid':forgotPasswordForm.controls['email'].valid}">
                        <input type="text" name="email" class="form-control modal-text-box" formControlName="email" required>
                        <label class="label-control">{{'Email' | translate}}*</label>
                    </div>
                </div>
                <div *ngIf="forgotPasswordForm.get('email').invalid && forgotPasswordForm.get('email').touched" style="color: #ff3834;text-align: left;font-size: 14px;margin-top: 5pt;">
                    {{'The valid email is required' | translate}}.
                </div>
                <button class="btn btn-account my-3" type="submit" [disabled]="forgotPasswordForm.get('email').invalid">
                    {{'Send password reset email' | translate}}
                </button>
                <div class="row mx-0 font-roboto-cad mt-3 link-box">
                    <div class="col-xl-6 login-button mx-auto">
                        <button class="btn btn-signin mx-2" data-toggle="tooltip" data-placement="top" (click)="goSignup()">
                            <i class="fas fa-user-plus"></i>
                        </button>
                        <div class="t-popover">
                            {{'Dont have an account? Sign up' | translate}}
                        </div>
                        <button class="btn btn-signin mx-2" (click)="goTosignIn()">
                            <i class="fas fa-sign-in-alt"></i>
                        </button>
                        <div class="t-popover">
                            {{'Already have an account? Sign in' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="signin" (window:resize)="onResize($event)" [style.minHeight.px]="myInnerHeight" *ngIf="isMailSent">
    <div class="login-box row mx-0 font-roboto-cad" style="display: grid;">
        <div class="col-xl-6 mx-auto">
            <div class="row mx-auto w-100 my-4">
                <img src="assets/img/signin-icon-white.png" alt="" class="logo1 mx-auto">
            </div>
            <div class="row mx-auto mt-4">
                <img src="assets/img/Teamium-white.png" alt="" class="img-fluid logo2 mx-auto">
                <p class="font-15 w-100 text-center text-white mt-2">PRODUCTION PLATFORM</p>
            </div>
        </div>

        <form [formGroup]="forgotPasswordForm" (submit)="sendMail()">
            <div class="row mx-0">
                <p class="font-roboto-cad font-16 text-white mt-4 mb-5 text-center w-100">Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.</p>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mx-auto">
                    <button class="btn btn-account" type="submit" (click)="goTosignIn()">{{'Return To sign in' | translate}}</button>
                    <div class="t-popover">
                        {{'Sign in' | translate}}
                    </div>
                </div>                
            </div>
        </form>
    </div>
</div>


