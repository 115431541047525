import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
// import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { StartTourComponent } from './start-tour/start-tour.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AuthGuard } from './core/services/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { AuthentificationComponent } from './authentification/authentification.component';
import { SecureLoginComponent } from './secure-login/secure-login.component';
import { TeamiumComponent } from './teamium/teamium.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SessionTimeoutModalComponent } from './session-timeout-modal/session-timeout-modal.component';


const routes: Routes = [
  { path: '',pathMatch: 'full', redirectTo: 'signin',  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component:  ResetPasswordComponent},
  { path: 'signup', component: SignupComponent },
  { path: 'start-tour', component: StartTourComponent, canActivate: [AuthGuard] },
  { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard] },
  { path: 'secure-login', component: SecureLoginComponent},
  { path: 'authentification', component: AuthentificationComponent},
  { path: 'timeOut', component: SessionTimeoutModalComponent,canActivate: [AuthGuard]},
  {
    path: "signin",
    // loadChildren:async () => (await import('./signin.module')).SigninModule
    loadChildren: async () => (await import('./signin.module')).SigninModule
  },
  
  {
    path: "teamium",
    component:TeamiumComponent, canActivate:[AuthGuard],
    children:[
      {
        path:"dashboard",
        loadChildren: async () => (await import ('./user-agenda-module/user-agenda-module.module')).UserAgendaModuleModule
      },
      {
        path:"show",
        loadChildren:async () => (await import ('./show-module/show-module.module')).ShowModuleModule
      },
      {
        path:"project",
        loadChildren:async () => (await import ('./project-module/project-module.module')).ProjectModuleModule
      },
      {
        path:"schedule",
        loadChildren:async () => (await import ('./schedule-module/schedule-module.module')).ScheduleModuleModule
      },
      {
        path:"equipment",
        loadChildren:async () => (await import ('./equipment-module/equipment-module.module')).EquipmentModuleModule
      },
      {
        path:"personal",
        loadChildren: async () => (await import ('./personal-module/personal-module.module')).PersonalModuleModule
      },
      {
        path:"capital",
        loadChildren:async () => (await import ('./assest-module/assest-module.module')).AssestModuleModule
      },
      {
        path:"client",
        loadChildren:async () => (await import ('./client-module/client-module.module')).ClientModuleModule
      },
      {
        path:"record",
        loadChildren:async () => (await import ('./report-module/report-module.module')).ReportModuleModule
      },
      {
        path:"vendor",
        loadChildren:async () => (await import ('./vendor-module/vendor-module.module')).VendorModuleModule
      },
      {
        path:"rate",
        loadChildren:async () => (await import ('./rate-module/rate-module.module')).RateModuleModule
      },
      {
        path:"function",
        loadChildren:async () => (await import ('./function-module/function-module.module')).FunctionModuleModule
      },
      {
        path:"bundle",
        loadChildren:async () => (await import ('./bundle-module/bundle-module.module')).BundleModuleModule
      },
      {
        path:"configuration",
        loadChildren:async () => (await import ('./configuration-module/configuration-module.module')).ConfigurationModuleModule
      },
      {
        path:"notification",
        loadChildren:async() => (await import ('./notification-module/notification-module.module')).NotificationModuleModule
      },
      {
        path:"role",
        loadChildren:async () => (await import ('./role-module/role-module.module')).RoleModuleModule
      },
      {
        path:"user",
        loadChildren:async () => (await import ('./user-module/user-module.module')).UserModuleModule
      },
      // {
      //   path:'',
      //   loadChildren: "./teamium.module#TeamiumModule"
      // }
    ]
   
    
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
