import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsActualPerFunctionComponent } from './projects-actual-per-function/projects-actual-per-function.component';
import { Routes, RouterModule } from '@angular/router';
import { BookingWorkOrderComponent } from './booking-work-order/booking-work-order.component';
import { ProjectActualPerCategoryComponent } from './project-actual-per-category/project-actual-per-category.component';

const routes: Routes = [
  {
    path: 'actual-per-function-chart',
    component: ProjectsActualPerFunctionComponent
  },
  {
    path: 'booking-order-chart',
    component: BookingWorkOrderComponent
  },
];
@NgModule({
  imports: [
    CommonModule,
  ], 
  exports:[
    ProjectsActualPerFunctionComponent,
    
    RouterModule
  ],
  declarations: [ProjectsActualPerFunctionComponent, ProjectActualPerCategoryComponent, ]
})
export class ReportChartsModule { }
