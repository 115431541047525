import { NgModule,CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ColorPickerModule } from 'ngx-color-picker';
import { GlobalSearchPipe } from './core/pipes/global-search.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { NgDragDropModule } from 'ng-drag-drop';
import { SliderModule } from 'primeng/slider';;
import { TreeModule } from 'primeng/tree';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';

import { PopoverModule } from "ngx-smart-popover";
import { DayPilotModule } from 'daypilot-pro-angular';
import { SpinnerComponent } from './spinner/spinner.component';
import { TeamiumComponent } from './teamium/teamium.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LineSearchPipe } from "./core/pipes/line-search.pipe";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MatIconModule } from "@angular/material/icon";
import {MatTabsModule} from '@angular/material/tabs';
import {  MatMenuModule } from "@angular/material/menu";
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import { GlobalSortPipe } from "./core/pipes/global-sort.pipe";
import {MatSliderModule} from '@angular/material/slider';
import { CustomTranslateLoader } from "./core/services/trans-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { QrCodeModule } from "ng-qrcode";
import { SaveDigitalSignature } from "./configuration-components/save-digital-signature/save-digital-signature.component";
import { DigitalSignatureComponent } from "./digital-signature/digital-signature.component";
import { ResetPasswordMyaccountComponent } from "./reset-password-myaccount/reset-password-myaccount.component";
import { SortAlphaPipe } from "./core/pipes/sort-alpha.pipe";
import { DeleteConfirmModalComponent } from "./delete-confirm-modal/delete-confirm-modal.component";
import { ShiftConfirmModalComponent } from "./shift-confirm-modal/shift-confirm-modal.component";
import { Angular2SignaturepadModule } from "angular2-signaturepad";
import { SidebarModalComponent } from "./sidebar-modal/sidebar-modal.component";
import { TimeCardComponent } from "./dashboard-modals/time-card/time-card.component";
import { GroupFilterPipe } from "./core/pipes/group-filter.pipe";
import { PlaceholderModalComponent } from "./placeholder-modal/placeholder-modal.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PersonalFilterPipe } from "./core/pipes/personal-filter.pipe";
import { CurrencysymbolPipe } from "./core/pipes/currencysymbol.pipe";
import { MatTableModule } from '@angular/material/table';
import { EditorModule } from "primeng/editor";
import { FunctionFilterPipe } from "./core/pipes/function-filter.pipe";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { EquipmentSearchPipe } from "./core/pipes/equipment-search.pipe";
import { TaskScheduledEditComponent } from "./scheduler/taskscheduled/task-scheduled-edit.component";
import { RecurrenceComponent } from "./scheduler/recurrence/recurrence.component";
import { CreateeventComponent } from "./scheduler/create-event/createevent.component";
import { CurrencySearchPipe } from "./core/pipes/currency-search.pipe";
import { VendorSearchPipe } from "./core/pipes/vendor-search-pipe";
import { VendorFilterPipe } from "./core/pipes/vendor-filter.pipe";
import { ShowProjectFilterPipe } from "./core/pipes/show-project-filter.pipe";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { GeneralSearchPipe } from "./core/pipes/general-search.pipe";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { GroupSearchPipe } from "./core/pipes/group-search.pipe";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EquipmentTrackComponent } from "./equipment-track/equipment-track.component";
import { AgmCoreModule } from "@agm/core";
import { MultipleWordSearchPipe } from "./core/pipes/multiple-word-search.pipe";
import {NgxPaginationModule} from 'ngx-pagination';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ProjectAndShowHeaderComponent } from "./project-and-show-header/project-and-show-header.component";
import {MatTooltipModule} from '@angular/material/tooltip';
import { CreateProjectEventComponent } from "./scheduler/show-project-scheduler/create-project-event/create-project-event.component";
import { DynamicFilterComponent } from "./dynamic-filter/dynamic-filter.component";
import { DraggableDirective } from "./scheduler/draggable.directive";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {TreeTableModule} from 'primeng/treetable';
import {PaginatorModule} from 'primeng/paginator';
import { ProcessViewComponent } from "./process-view/process-view.component";
import { TaskAndWorkorderComponent } from "./task-and-workorder/task-and-workorder.component";
import {MatBadgeModule} from '@angular/material/badge';
import { StockGenericComponent } from "./scheduler/Stock-Generic/stock-generic/stock-generic.component";
import { AddMediaModalComponent } from "./add-media-modal/add-media-modal/add-media-modal.component";
import { AddShippingModalComponent } from "./add-shipping-modal/add-shipping-modal/add-shipping-modal.component";
import { AddAssetModalComponent } from "./add-asset-modal/add-asset-modal.component";
// import { SessionTimeoutModalComponent } from "./session-timeout-modal/session-timeout-modal.component";


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;





const routes: Routes = [{

}];

@NgModule({
  exports: [RouterModule],
  imports: []
})
export class SharedRoutingModule {}

@NgModule({
  imports:      [ 
    CommonModule,
    PickerModule,
    EmojiModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ColorPickerModule,TextMaskModule,
    NgDragDropModule.forRoot(),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC7YMFlaEN66KhtX9mNBYnzCWERCqmgn-Q',
      // apiKey: 'AIzaSyBTV-MaDp2EJWZLbRt4lnaAKqsHD6-f0Bs',
      libraries: ['places']
    }),
    
  
    TranslateModule,
    SliderModule,
    TreeModule,
    BlockUIModule,
    CalendarModule,
    TreeTableModule,
    PaginatorModule,
    PopoverModule,
    DayPilotModule,
    // ScheduleModule,
    NgbModule,
    PdfViewerModule,
    MatIconModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatInputModule,
    TextFieldModule,
    MatButtonModule,
    MatBadgeModule,
    MatSliderModule,
    MatDividerModule,
    QrCodeModule,
    Angular2SignaturepadModule,
    DragDropModule,
    InfiniteScrollModule,
    HttpClientModule,
    MatTableModule,
    EditorModule,
    MatMenuModule,
    NgxPaginationModule,
    NgxDatatableModule,
    ScrollingModule,
    MatStepperModule,


   
   ],
   declarations: [
    TeamiumComponent,
    SidebarComponent,
    TimeCardComponent,
    ProcessViewComponent,
    SpinnerComponent,
    LineSearchPipe,
    GlobalSortPipe,
    ProjectAndShowHeaderComponent,
    HeaderComponent,
    DynamicFilterComponent,
    GlobalSearchPipe,
    DraggableDirective,
    MultipleWordSearchPipe,
    SaveDigitalSignature,
    DigitalSignatureComponent,
    ResetPasswordMyaccountComponent,
    TaskAndWorkorderComponent,
    SortAlphaPipe,
    GroupFilterPipe,
    // SessionTimeoutModalComponent,
    DeleteConfirmModalComponent,
    ShiftConfirmModalComponent,
    CreateProjectEventComponent,
    SidebarModalComponent,
    PlaceholderModalComponent,
    PersonalFilterPipe,
    CurrencysymbolPipe,
    FunctionFilterPipe,
    NotFoundPageComponent,
    EquipmentSearchPipe,
    TaskScheduledEditComponent,
    AddMediaModalComponent,
    AddShippingModalComponent,
    AddAssetModalComponent,
    RecurrenceComponent,
    CreateeventComponent,
    StockGenericComponent,
    CurrencySearchPipe,
    VendorSearchPipe,
    VendorFilterPipe,
    ShowProjectFilterPipe,
    GeneralSearchPipe,
    ComingSoonComponent,
    GroupSearchPipe,
    EquipmentTrackComponent,

    // ShowSearchPipe,
    // CreateShowComponent,
    // CreateProjectBudgetingComponent,
    // ProjectSearchPipe

  ],
  exports: [ 
    TeamiumComponent,
    SidebarComponent,
    ScrollingModule,
    ProcessViewComponent,
    ColorPickerModule,
    DynamicFilterComponent,
    TaskAndWorkorderComponent,
    TranslateModule,
    AgmCoreModule,
    DayPilotModule,
    ProgressSpinnerModule,
    BlockUIModule,
    SpinnerComponent,
    LineSearchPipe,
    ProjectAndShowHeaderComponent,
    SliderModule,
    NgbModule,
    MatSliderModule,
    NgxPaginationModule,
    NgxDatatableModule,
    CalendarModule,
    PdfViewerModule,
    MatIconModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatInputModule,
    TextFieldModule,
    GlobalSortPipe,
    MatButtonModule,
    MatButtonToggleModule,
    GlobalSearchPipe,
    MultipleWordSearchPipe,
    PlaceholderModalComponent,
    HeaderComponent,
    QrCodeModule,
    SaveDigitalSignature,
    DigitalSignatureComponent,
    ResetPasswordMyaccountComponent,
    SortAlphaPipe,
    GroupFilterPipe,
    DraggableDirective,
    // SessionTimeoutModalComponent,
    DeleteConfirmModalComponent,
    ShiftConfirmModalComponent,
    TimeCardComponent,
    Angular2SignaturepadModule,SidebarModalComponent,
    DragDropModule,
    TranslateModule,
    InfiniteScrollModule,
    PersonalFilterPipe,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgDragDropModule,
    TreeTableModule,
    PaginatorModule,
    CurrencysymbolPipe,
    TextMaskModule,
    MatTableModule,
    MatTooltipModule,
    MatTabsModule,
    EditorModule,
    FunctionFilterPipe,
    NotFoundPageComponent,
    MatMenuModule,
    EquipmentSearchPipe,
    TaskScheduledEditComponent,
    RecurrenceComponent,
    CreateeventComponent,
    StockGenericComponent,
    CurrencySearchPipe,
    VendorSearchPipe,
    VendorFilterPipe,
    AddMediaModalComponent,
    AddShippingModalComponent,
    AddAssetModalComponent,
    ShowProjectFilterPipe,
    NgxMaskModule,
    GeneralSearchPipe,
    PopoverModule,
    ComingSoonComponent,
    GroupSearchPipe,
    EquipmentTrackComponent,
    CreateProjectEventComponent,
  

    
  ]
})
export class SharedModule {}