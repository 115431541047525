<!-- Modal -->

<div
  id="myModal"
  class="modal in add-equipment-modal"
  role="dialog"
  style="display: block; top: 54px;"
>
  <div class="modal-dialog modal-md resetPass-My-Ac">
    <form [formGroup]="resetPasswordForm">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="true"
            (click)="closeModal()"
          >
            &times;
          </button>
          <h4 class="title">{{ "Reset Password" | translate }}</h4>
        </div>
        <div class="modal-body">
          <div class="create-equipment">
            <div class="inner-content pad-20">
              <div class="steps add-info basicInfo">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-content">
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="input-row">
                            <label>{{ "Old Password" | translate }} *</label>
                            <input
                              type="password"
                              class="form-control"
                              autofocus
                              placeholder="Old Password"
                              formControlName="existingPassword"
                            />
                            <span
                              *ngIf="
                                (existingPassword.dirty ||
                                  existingPassword.touched) &&
                                existingPassword.invalid &&
                                existingPassword.errors.required
                              "
                              style="
                                color: #a94442;
                                text-align: left;
                                font-size: 14px;
                                margin-top: 5pt;
                              "
                              >{{
                                "Old password is required" | translate
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="col-xs-12">
                          <div class="input-row">
                            <label>{{ "New Password" | translate }} *</label>
                            <input
                              type="password"
                              class="form-control"
                              placeholder="New Password"
                              formControlName="password"
                            />
                            <span
                              *ngIf="
                                password.invalid &&
                                (password.dirty || password.touched)
                              "
                              style="
                                color: #a94442;
                                text-align: left;
                                font-size: 14px;
                                margin-top: 5pt;
                              "
                            >
                              <span *ngIf="password.errors.required">{{
                                "New password is required" | translate
                              }}</span>
                              <span *ngIf="password.errors.minlength">{{
                                "Minimum password length is 6" | translate
                              }}</span>
                              <span *ngIf="password.errors.maxlength">{{
                                "Maximum password length is 20" | translate
                              }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="col-xs-12">
                          <div class="input-row">
                            <label
                              >{{ "Confirm New Password" | translate }} *</label
                            >
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Confirm New Password"
                              formControlName="confirmPassword"
                            />
                            <span
                              *ngIf="
                                password.valid &&
                                (confirmPassword.dirty ||
                                  confirmPassword.touched) &&
                                (confirmPassword.invalid ||
                                  resetPasswordForm.invalid)
                              "
                              style="
                                color: #a94442;
                                text-align: left;
                                font-size: 14px;
                                margin-top: 5pt;
                              "
                            >
                              <span
                                *ngIf="confirmPassword.hasError('required')"
                                >{{
                                  "Confirm password is required" | translate
                                }}</span
                              >
                              <span
                                *ngIf="
                                  !confirmPassword.hasError('required') &&
                                  resetPasswordForm.hasError('passwordMatch')
                                "
                                >{{
                                  "Confirm password does not match" | translate
                                }}</span
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="button-set text-center" class="col-md-12">
              <button
                type="submit"
                class="btn btn-first"
                (click)="changePassword()"
                [disabled]="resetPasswordForm.invalid"
              >
                {{ "Save And Close" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
