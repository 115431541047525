import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
// import { AuthService } from './auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private sessionTimeoutComponent: AppComponent,private router:Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get("skip"))
        return next.handle(request);

    const token = sessionStorage.getItem('token');
    if (token) {

      const clonedRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
        
      });
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Handle session timeout
            
            this.router.navigate(['timeOut'])
          }
          return throwError(error);
        })
      );;

    }
    return next.handle(request);

  }


}
