import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlsService } from "../urls.service";

@Injectable({
  providedIn: 'root'
})
export class BookingRequestService {

  constructor(private http: HttpClient, private url: UrlsService) { }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  getBookingColumn(){
    return this.http.get(`${this.url.urlString}project/column-details`).toPromise();
  }

  getMediaColumn(){
    return this.http.get(`${this.url.urlString}project/media/column-details`).toPromise();
  }

  getAllTimeZone(){
 
    return this.http.get(`${this.url.urlString}time-zone`).toPromise()
  }

  getTempBalanceOfBooking(lineId:any){
    return this.http.get(`${this.url.urlString}project/booknow/${lineId}`).toPromise();
  }

  getRequestColumn(){
    return this.http.get(`${this.url.urlString}project/request-column-details`).toPromise();
  }

  deleteRefrence(id:any){
    return this.http.delete(`${this.url.urlString}budget/bookingPreference/${id}`,{responseType: 'text'}).toPromise();

  }

  getLinesBooking(projectId:any,lastBookingId:any){
    return this.http.get(`${this.url.urlString}budget/booking/${projectId}/${lastBookingId}`).toPromise();
  }

  getRequestLinesBooking(projectId:any,lastBookingId:any){
    return this.http.get(`${this.url.urlString}project/booking/${projectId}/${lastBookingId}`).toPromise();
  }

  getRecordNetwork(projectId:any){
    return this.http.get(`${this.url.urlString}project/get-record-neworks/${projectId}`).toPromise();
  }

  getAllRecordFromat(projectId:any){
    return this.http.get(`${this.url.urlString}project/deliverable/${projectId}`).toPromise();
  }

  getProjectContactsById(projectId:any){
    return this.http.get(`${this.url.urlString}project/contacts/${projectId}`).toPromise();
  }

  getProjectContactsByIdRequest(projectId:any){
    return this.http.get(`${this.url.urlString}budget/contacts/${projectId}`).toPromise();
  }

  getProjectFee(projectId:any){
    return this.http.get(`${this.url.urlString}budget/getProjectFee/${projectId}`).toPromise();
  }

  setProjectFee(formData:any,projectId:any){
    return this.http.post(`${this.url.urlString}budget/getProjectFee/${projectId}`,formData).toPromise();
  }

  getInvoiceDetailsWithProjectID(projectId:any){
    return this.http.get(`${this.url.urlString}invoice/${projectId}`).toPromise();
  }

  getInvoiceAmount(projectId:any){
    return this.http.get(`${this.url.urlString}invoice/amount/${projectId}`).toPromise();
  }

  getProjectInvoiceType(projectId:any){
    return this.http.get(`${this.url.urlString}invoice/invoice-type/${projectId}`).toPromise();
  }

  getTotalAmount(projectId:any){
    return this.http.get(`${this.url.urlString}invoice/totalAmount/${projectId}`).toPromise();
  }

  setCreditNote(formData:any,projectId:any){
    return this.http.post(`${this.url.urlString}invoice/project/invoice/${projectId}`,formData).toPromise();
  }

  addLine(formData:any,projectId:any){
    return this.http.post(`${this.url.urlString}project/booking/${projectId}`,formData).toPromise();
  }

  addResourceToLine(formData:any){
    return this.http.post(`${this.url.urlString}event`,formData).toPromise();
  }

  setTabaleIndex(projectId:any,fromBooking:any,previousIndex:any,currentIndex:any){
    return this.http.post(`${this.url.urlString}project-planning/dropAndDragEvent/${projectId}/${fromBooking}/${previousIndex}/${currentIndex}`,{}).toPromise();
  }

  getAllBookingsBytable(projectId:any){
    return this.http.get(`${this.url.urlString}project/project-checkout/project/${projectId}`).toPromise();
  }

  setProjectShare(formData:any){
    return this.http.post(`${this.url.urlString}project/set-shared-contacts/`,formData).toPromise();
  }

  getAllProjectShare(projectId:any){
    return this.http.get(`${this.url.urlString}project/set-shared-contacts/${projectId}`).toPromise();

  }

  deleteProjectShare(projectId:any,id){
    return this.http.delete(`${this.url.urlString}project/set-shared-contacts/${projectId}/${id}`,{responseType: 'text'}).toPromise();

  }

  sendNotificationToOne(recordId:any,bookingId:any){
    return this.http.post(`${this.url.urlString}notification/notify-one/${recordId}/${bookingId}`,{}).toPromise();
  }

  // getAvailableRresourceEvent(startDate,endDate,functionId){
  //   return this.http.get(`${this.url.urlString}event/cal-event/availability/${functionId}/${startDate}/${endDate}`).toPromise()

  // }

  getAvailableRresourceEvent(formData){
    return this.http.get(`${this.url.urlString}event/availability?${this.serialize(formData)}`).toPromise()

  }

  getResourcesEvent(formData){
    return this.http.get(`${this.url.urlString}resource//timeline/functions/${formData}`).toPromise()

  }

  getBookingDetails(resource){
    return this.http.get(`${this.url.urlString}booking/bookingdetails/${resource}`).toPromise()

  }

  setBookingDetails(resource){
    return this.http.post(`${this.url.urlString}booking/bookingdetails/${resource}`,{}).toPromise()

  }


  getAvailableLicense(startDate,endDate,functionId){
    return this.http.get(`${this.url.urlString}event/service/availability/${functionId}/${startDate}/${endDate}`).toPromise()

  }

  deleteInvoice(invoiceId:any){
    return this.http.delete(`${this.url.urlString}invoice/invoice-type/${invoiceId}`,{responseType: 'text'}).toPromise()
  }

  getallAsignedUser(projectId:any){
    return this.http.get(`${this.url.urlString}project/mobile-booking/${projectId}/0`).toPromise();
  }
  getAllCallSheet(projectId:any,planningEventId){
    return this.http.get(`${this.url.urlString}call-sheet/${projectId}/${planningEventId}`).toPromise();
  }
  setAllCallSheet(projectId:any,eventId){
    return this.http.post(`${this.url.urlString}call-sheet/${projectId}/${eventId}`,{}).toPromise();
  }


  callSheetNotification(callSheetId:any,notify){
    return this.http.post(`${this.url.urlString}notify/${callSheetId}/${notify}`,{}).toPromise();
  }


  getlineById(lineId:any){
    return this.http.get(`${this.url.urlString}booking/${lineId}`).toPromise();
  }

  getRosteravl(formData:any){
    return this.http.get(`${this.url.urlString}roster/timeline-event/by-range/?${this.serialize(formData)}`).toPromise();
  }




}
