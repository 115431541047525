import { Component, OnInit, ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-teamium',
  templateUrl: './teamium.component.html',
  styleUrls: ['./teamium.component.scss']
})
export class TeamiumComponent implements OnInit {

  public activeMenu: string;


  constructor(private cdr: ChangeDetectorRef) {

  }
  ngOnInit() {
  }

  
  showHideNav(){
    var x = document.getElementById("closeIt");
    var y = document.getElementById("dashboard");
    var a = document.getElementById("hello");
    // var w = document.getElementById("navLink")
    if(x.style.display === "none"){
      document.getElementById("closeIt").style.display = "block";
      document.getElementById("dashboard").style.paddingLeft ="220px";
      document.getElementById("navLink").style.left ="220px";      
      a.classList.remove("logo-small");
      a.classList.add("logo-large");
    }
    else{
      document.getElementById("closeIt").style.display = "none";
      document.getElementById("dashboard").style.paddingLeft ="0px";
      document.getElementById("navLink").style.left ="60px";
      a.classList.add("logo-small");
      a.classList.remove("logo-large");
    }
  }

  changeMenuItem($event) {
    this.activeMenu = $event;
    this.cdr.detectChanges();
  }

}