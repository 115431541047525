<!--

<div class="container"  >
  <div class="row">
    <div class="col" style="width: 40%;"> <div style="display: block" >
      <canvas baseChart  
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
    </div></div>
    <div class="col" style="width: 40%;"><div style="display: block">
      <canvas baseChart  
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType">
      </canvas>
    </div></div>
    <div class="w-100"></div>
    <div class="col" style="width: 40%;"><div class="chart">
      <canvas baseChart    
        [data]="pieChartData"
        [labels]="pieChartLabels"
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [colors]="pieChartColors"
        [legend]="pieChartLegend">
      </canvas>
    </div></div>
    <div class="col" style="width: 40%;">  <div style="display: block">
      <canvas baseChart     
        [datasets]="hbarChartData"
        [labels]="hbarChartLabels"
        [options]="hbarChartOptions"
        [plugins]="hbarChartPlugins"
        [legend]="hbarChartLegend"
        [chartType]="hbarChartType">
      </canvas>
    </div></div>
  </div>
</div>
-->


<div class="row ">
    <div class="col-lg-12">
        <div class=" gx-card " data-toggle="modal" data-target="#barModal">
            <div class="card-body">
                <!-- <canvas baseChart [datasets]="budgetAmountSpentbarChartData" [labels]="budgetAmountSpentbarChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="barChartColors">
        </canvas> -->
            </div>
        </div>
    </div>
</div>
<!--
  <div class="col-lg-6">
    <div class="gx-card ">
      <div class="card-body">
        <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
          (chartClick)="chartClicked($event)">
        </canvas> <span style="margin:auto; display:table;"> <button type="button" class="btn btn-primary" data-toggle="modal"
            data-target="#doughnutModal">
            Expand
          </button>

        </span> </div>
    </div>
  </div>
</div>
-->
<div class="row">
    <div class="col-lg-6 ">
        <div class="gx-card" data-toggle="modal" data-target="#pieModal">
            <div class="card-body">
                <!-- <canvas baseChart [data]="percentBudgetValues" [labels]="budgetAmountSpentpieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">   </canvas> -->


            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="gx-card " data-toggle="modal" data-target="#hbarModal">
            <div class="card-body">
                <!-- <canvas baseChart [datasets]="amountSpentPerMonthToDateData" [labels]="budgetAmountSpentbarChartLabels" [options]="hbarChartOptions" [plugins]="hbarChartPlugins" [legend]="hbarChartLegend" [chartType]="hbarChartType" [colors]="barChartColors"> -->
        <!-- </canvas> -->
       </div>
        </div>
    </div>



</div>



<span class="popup">
  <div class="modal" id="barModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
    <!-- <canvas baseChart [datasets]="budgetAmountSpentbarChartData" [labels]="budgetAmountSpentbarChartLabels" [options]="barChartOptionsModal" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="barChartColors"> -->
        <!-- </canvas> -->
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
</div>
</div>
</div>
</div>
<div class="modal" id="doughnutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <!-- <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType" (chartClick)="chartClicked($event)"> -->
      <!-- </canvas> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="pieModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title text-white" id="exampleModalLongTitle">Percentage Budget Per Project</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <!-- <canvas baseChart [data]="percentBudgetValues" [labels]="budgetAmountSpentpieChartLabels" [chartType]="pieChartType" [options]="pieChartOptionsModal" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">   </canvas> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="hbarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <!-- <canvas baseChart [datasets]="amountSpentPerMonthToDateData" [labels]="budgetAmountSpentbarChartLabels" [options]="hbarChartOptions" [plugins]="hbarChartPlugins" [legend]="hbarChartLegend" [chartType]="hbarChartType" [colors]="barChartColors">
    </canvas> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
</span>