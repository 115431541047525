import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input()
  blockedPanel: boolean = false;
  customMessage = 'Loading...';

  messages = [
    'Hang tight, we\'re almost there!',
    'Exploring the digital realm...',
    'Polishing pixels...',
    'Loading the awesomeness...',
    'Building castles in the digital sand...',
    'Gathering stardust...',
    'Painting rainbows on the screen...',
  ];

  constructor() { }

  ngOnInit() {
    setInterval(() => {
      this.updateCustomMessage();
    }, 5000);
  }

  updateCustomMessage() {
    // Select a random message from the list
    const randomIndex = Math.floor(Math.random() * this.messages.length);
    this.customMessage = this.messages[randomIndex];
  }

}
