import { Injectable, OnInit } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from '../../../../node_modules/rxjs';
import { SharedService } from './shared-service/shared.service';
import { filter } from 'rxjs/operators'
import { Router,NavigationEnd  } from '@angular/router';
import { CommonUtilService } from './common-util-service';
import { TranslateService } from '@ngx-translate/core';

export interface UserDetailsData {
  timeZone:'UTC'
}

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
public ud:UserDetailsData={
  timeZone:'UTC'
}

  loggedInUserDetails: any;
  private fetched = false;
  private funcs = [];
  constructor(private httpService: HttpService,private translateService:TranslateService,private sharedService: SharedService,private commonService:CommonUtilService,private router:Router) {
    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if ( event.id === 1 && event.url === event.urlAfterRedirects && window.location.pathname != '/signin' && window.location.pathname != '/reset-password') {
        this.fetchUserDetails();
      }
    })

   

  }


  isValidateUserRoleForModule(roleName: string, cb) {
    this.getLoggedInUser((res) => {
      return cb(res.role.some(role => role.roleName === roleName))
    })
  }

  getLoggedInUser(cb) {
    if (!this.loggedInUserDetails) {
      this.httpService.callApi('getLoggedInUser', {}).subscribe((response) => {
        this.loggedInUserDetails = response;
        this.loggedInUserDetails.locale = this.getLocaleByLanguage( this.loggedInUserDetails.uiLanguage);        
        cb(this.loggedInUserDetails);
      }, (error) => {
        
      })
    } else {
      cb(this.loggedInUserDetails);
    }

  }


update(res){
  this.ud.timeZone = res.userSettingDTO.timezone;
}

  fetchUserDetails(){
    let userID =  sessionStorage.getItem("userId")
    this.httpService.callApi('getLoggedInUser',{}).subscribe((response) => {
      this.sharedService.setLoggedInUserData(response);
      this.sharedService.setFcmToken();

      this.fetched = true;
      this.update(response);
      this.loggedInUserDetails = response;
      this.useLanguage(this.loggedInUserDetails.uiLanguage ? this.loggedInUserDetails.uiLanguage : 'English')
      this.loggedInUserDetails.locale = this.getLocaleByLanguage( this.loggedInUserDetails.uiLanguage);
      this.getFilters()
  
      
    }, (error) => {
      
    })
  }

  useLanguage(language: string) {
    switch (language) {
      case 'English':
        language = 'en';
        break;
      case 'French':
        language = 'fr';
        break;
      case 'German':
        language = 'de';
        break;
      case 'Arabian':
        language = 'Arabic';
        break;
       case 'Spanish':
        language = 'Spanish';
        break;
       case 'Italian':
        language = 'Italian';
        break;
       case 'Russian':
        language = 'Russian';
        break;
       case 'Indi':
        language = 'Indi';
        break;
      default:
        language = 'en';
    }
    this.translateService.use(language);
    sessionStorage.setItem("selectedLanguage", language);
  }



  call(fn: () => any) {
    if (!this.fetched) {
      this.funcs.push(fn);
    } else {
      fn();
    }
  }


  getLocaleByLanguage(lang) {

    if (lang==null || lang == undefined) 
    return"en-us";
    if (lang === "English")
      return "en-us";
    if (lang === "French")
      return "fr-fr";
    if (lang === "Italian")
      return "it-it";
    if (lang === "Arabian")
      return "en-us";
    if (lang === "Chinese")
      return "en-us";
    if (lang === "German")
      return "en-us";
    if (lang === "Spanish")
      return "en-us";

  }

  async getFilters(){
    try {
      let userid = sessionStorage.getItem('userId');
      const res:any = await this.commonService.getfilters(userid);
        let filterData = res;
        if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
          if(filterData.values != null){
            for (const property in filterData.values) {
              for (let i = 0; i < filterData.values[property].length; i++) {
               if(filterData.values[property][i].filterId != undefined){
                delete filterData.values[property][i].filterId
               }

               if(filterData.values[property][i].id != undefined){
                delete filterData.values[property][i].id
               }
                
              }
             
            }
          }
        }
       console.log(filterData)
      this.sharedService.setuserFilters(filterData)
      this.funcs.forEach(element => {
        element();
      });
      this.funcs = [];
    } catch (error) {
      console.log(error)
      this.funcs.forEach(element => {
        element();
      });
      this.funcs = [];
    }
}

}
