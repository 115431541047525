<header class="single-header">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="logo">
                    <img src="../../assets/img/Teamium-white.png" alt="" class="default-logo">
                </div>
            </div>
        </div>
    </div>
</header>