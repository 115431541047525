import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleService } from '../core/services/user-role.service';

@Component({
  selector: 'app-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss']
})
export class SessionTimeoutModalComponent  {
  @Input()
  isSessionTimeout: boolean = false;


  constructor(private router: Router,private userRoleService: UserRoleService) {}



  onLogout() {
    // Clear session data and navigate to login
    sessionStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['signin']).then(() => {
      console.log('reload called')
    setTimeout(() => {
      document.location.reload();
    },1000);
    });
    this.userRoleService.clear();
  }
}
