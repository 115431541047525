import { Component, ViewChild, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, ActivationEnd } from '@angular/router';
import { DataService } from './data.service';
import { UserRoleService } from './core/services/user-role.service';
import { OktaService } from './core/services/okta.service';
import { CommonUtilService } from './core/services/common-util-service';
import { Subject } from 'rxjs';
import { SharedService } from './core/services/shared-service/shared.service';
import { Canal9OktaService } from './core/services/canal9-okta.service';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { onBackgroundMessage } from "firebase/messaging/sw";
import { environment } from "../environments/environment";
import { ToastrService } from 'ngx-toastr';
import { ThemeService } from './core/services/theme/theme.service';
import { style } from '@angular/animations';
import { AzureService } from './core/services/azure.service';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
 public liClicked:boolean;
 public blockedPanel:boolean=false;
 title = 'af-notification';
  message:any = null;

  primaryColor: string;
  secondaryColor: string;

  isSessionTimeout = false;



  constructor(private dataService :DataService,private cd:ChangeDetectorRef,private toster:ToastrService,
    private router: Router,private elementRef: ElementRef,
    private userRoleService: UserRoleService, private oktaService: OktaService,private azureService:AzureService,private canal9okta:Canal9OktaService,
    private commonService: CommonUtilService,public theme: ThemeService,
    private sharing: SharedService){


      if (window.location.hostname == 'canal9.teamium.net'){
        this.canal9okta.configure();
      } else if (window.location.hostname == 'qa.teamium.net'){
        this.oktaService.configure();
      } else if(window.location.hostname == 'prodigious.teamium.net'){
        this.azureService.configure();
      } else {
          // this.oktaService.configure();
          // setTimeout(() => {
            
            // this.azureService.configure();
          // }, 1000);
          
      }
       
        this.liClicked = this.dataService.isWrapperActive;
        // this.sw.promptUser();
  }

  ngOnInit () {
    this.router.events.subscribe((event:any)=> {
      // console.log(event)
        if (event instanceof RouteConfigLoadStart) {
          this.blockedPanel = true;
        } else if (event instanceof RouteConfigLoadEnd) {
            this.blockedPanel = false;
        }
       
    });

    this.theme.loadTheme().subscribe((colors) => {
     for (const key in colors) {
      if (Object.prototype.hasOwnProperty.call(colors, key)) {
        document.documentElement.style.setProperty(`--${key}`,colors[key])

      }
  }
  });

    // this.requestPermission();
    // this.listen();  

    this.getCssVariableValue('--primary')
    // this.getColors();
    // this.getProjectList();
}

getCssVariableValue(variableName: string) {
}

// requestPermission() {
//   const messaging = getMessaging();
//   getToken(messaging, 
//    { vapidKey: environment.firebaseConfig.vapidkey}).then(
//      (currentToken) => {
//        if (currentToken) {
//         this.sharing.CurrentUserFcmToken =  currentToken;
         
//        } else {
//          console.log('No registration token available. Request permission to generate one.');
//        }
//    }).catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//   });
// }

// listen() {
//   const messaging = getMessaging();
//   onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     this.message=payload;
//     this.sharing.getnotification(true)
//     this.toster.success(payload.notification.body,payload.notification.title)
//   });


// }









}
