<app-spinner [blockedPanel]='blockedPanel'></app-spinner>
<div class="main-header font-roboto-cad" id="main-header" #mainHeader>
    <div class="inner-page">
            <img src="assets/img/prodi_main_w.png" alt="" *ngIf="checkforProdi() && themeData.darkMode" style="float: left;" class="img-fluid logo2 mx-auto">
            <img src="assets/img/prodi_main.png" alt="" *ngIf="checkforProdi() && !themeData.darkMode" style="float: left;" class="img-fluid logo2 mx-auto">
      
        <div class="font-20 d-inline-block float-left" [ngStyle]="{ 'left': leftDistance}" style="position: relative; top: 12px;">
            <div  style="height: 60%;" [ngClass]="notesSearch == null || notesSearch == '' ?'input-search-box':'hasValue'" >
                <i class="fa fa-search pointer-event-none" aria-hidden="true"></i>
                <input type="text"  style="height: 30px; display: inline-block !important;"   [(ngModel)]="notesSearch" (keyup)="applyFilter($event)"
                    class="pl-5 input-search form-control font-20 font-league bg-none border-0">
                    <i class="fas fa-globe cursor-p"  (click)="showGlobalModal =! showGlobalModal" id="showGlobalModal1"></i>
            </div>
          
            <div class="global-search-modal shadow py-3 px-3" [hidden]="!showGlobalModal" id="showGlobalModal1">
                <div class="row mx-0">
                    <div class="col-md-8">
                        <mat-form-field class="example-chip-list" appearance="fill" style="width: 100%; font-size: 14px;">
                            <mat-chip-list #chipList aria-label="tags Selection">
                                <mat-chip *ngFor="let item of assignedTags" [selectable]="selectable" [removable]="removable" (removed)="removeAssignedTags(item)">
                                    {{item.tagKeyword}}
                                    <button matChipRemove *ngIf="removable">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input class="bootstrap-tagsinput" style="box-shadow: none;" placeholder="{{'Select Tags'}}" #tagInput [formControl]="tagsInput" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="callSerachTagsFn($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="callSerachTagsFn($event)">
                                <mat-option *ngFor="let item of filteredOptions | async" [value]="item" id="showGlobalModal1" style="z-index:999999 !important">
                                    {{item.tagKeyword}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mx-0 txt-dark mt-3 px-3 mb-4">
                    <span class="d-inline-block bg-txt-primary px-4 py-2 rounded bg-primary-t txt-btn shadow mr-2 cursor-p" (click)="toggleElement =! toggleElement" [ngClass]="toggleElement ? 'bg-toggle-active':'bg-txt-primary'">
                        <i class="fas fa-align-left"></i>
                    </span>
                    <span class="d-inline-block bg-txt-primary px-4 py-2 rounded bg-primary-t txt-btn shadow mr-2 cursor-p" (click)="toggleElement1 =! toggleElement1" [ngClass]="toggleElement1 ? 'bg-toggle-active':'bg-txt-primary'">
                        <i class="far fa-folder"></i>
                    </span>
                    <span class="d-inline-block bg-txt-primary px-4 py-2 rounded bg-primary-t txt-btn shadow mr-2 cursor-p">
                        <i class="fas fa-video"></i>
                    </span>
                    <span class="d-inline-block bg-txt-primary px-4 py-2 rounded bg-primary-t txt-btn shadow mr-2 cursor-p">
                        <i class="far fa-user"></i>
                    </span>
                    <span class="d-inline-block bg-txt-primary px-4 py-2 rounded bg-primary-t txt-btn shadow mr-2 cursor-p">
                        <i class="far fa-caret-square-right"></i>
                    </span>
                    <span class="d-inline-block bg-txt-primary px-4 py-2 rounded bg-primary-t txt-btn shadow mr-2 cursor-p">
                        <i class="fas fa-users"></i>
                    </span>
                </div>
                <div class="row mx-0 px-3 mt-3 font-roboto-cad txt-light-gog border-bottom" *ngFor="let obj of searchResults">
                    <ng-container *ngIf="obj.resourceType == 'Project'">
                        <div class="col-md-8 text-left pl-0">
                            <h5 class="font-bold cursor-p" title="{{'Redirect' | translate }}" *ngIf="obj?.projectFlag" (click)="goToDetailsFromSearch(obj.routerLink,obj.id)">
                               {{settings.projectLabel | translate}} : {{obj.name | translate}}
                            </h5>
                            <p class="font-13 teamium-color" *ngFor="let keyword of obj.listKeyWordMatch">{{keyword.resourceType | translate}} : {{keyword.tagKeyword | translate}}</p>
    
                        </div>
                        <div class="col-md-2 text-center px-2">
                            <i class="far fa-folder"></i>
                        </div>
                        <div class="col-md-2 text-center px-0">
                            <p class="font-14 color-orange m-0">{{obj.date | date:derivedSettings.dateFormatLine}}</p>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="obj.resourceType == 'Staff'">
                        <div class="col-md-8 text-left pl-0">
                            <h5 class="font-bold cursor-p" title="{{'Redirect' | translate }}"  (click)="goToDetailsFromSearchP(obj.routerLink,obj.id)">
                               {{'Personnel' | translate}} : {{obj.name | translate}}
                            </h5>
                            <p class="font-13 teamium-color" *ngFor="let keyword of obj.listKeyWordMatch">{{keyword.resourceType | translate}} : {{keyword.tagKeyword | translate}}</p>
    
                        </div>
                        <div class="col-md-2 text-center px-2">
                            <i class="far fa-user"></i>
                        </div>
                        <div class="col-md-2 text-center px-0">
                         
                        </div>
                    </ng-container>
  
                </div>
                <!-- <div class="row mx-0 px-3 mt-3 font-roboto-cad txt-light-gog border-bottom">
                    <div class="col-md-8 text-left pl-0">
                        <h5 class="font-bold">
                            Resotre Avatar
                        </h5>
                        <p class="font-13 teamium-color">Project: Freelancer rate card</p>
                    </div>
                    <div class="col-md-2 text-center px-2">
                        <img src="../../assets/img/vdoImg.png" alt="" class="w-100">
                    </div>
                    <div class="col-md-2 text-center px-0">
                        <p class="font-14 color-orange m-0">04/28/22</p>
                        <h4>PDF</h4>
                    </div>
                </div>
                <div class="row mx-0 px-3 mt-3 font-roboto-cad txt-light-gog border-bottom">
                    <div class="col-md-8 text-left pl-0">
                        <h5 class="font-bold">
                            Resotre Avatar
                        </h5>
                        <p class="font-13 teamium-color">Project: Freelancer rate card</p>
                    </div>
                    <div class="col-md-2 text-center px-2">
                        <img src="../../assets/img/vdoImg.png" alt="" class="w-100">
                    </div>
                    <div class="col-md-2 text-center px-0">
                        <p class="font-14 color-orange m-0">04/28/22</p>
                        <h4>PDF</h4>
                    </div>
                </div> -->
            </div>
        </div>
        <ul class="notification-list" id="notificaton">
            <li>
                <!-- Project Notes -->
                <div class="header-project-note" style="position: relative;">
                    <a href="javascript:void(0)" id="projectNotess" (click)="projectNotes =! projectNotes;getAllUserTask()" title="{{'Notes' | translate}}" class="notes">
                        <span >
                            <i class="fa fa-user-o" aria-hidden="true"></i>
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                    </a>
                    <div class="headerProjectNots" *ngIf="projectNotes" id="projectNotess">
                        <mat-tab-group  animationDuration="1000ms" mat-align-tabs="center" >
                            <mat-tab >
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon" class="mr-1">notes</mat-icon>
                                    Notes
                                  </ng-template>
                                <div class="row mx-0 d-flex align-items-center" style="border-bottom: 2px solid #eee; height: 45px; ">
                                    <div class="col-4 justify-content-start">
                                        <select  placeholder="Select List" (change)="filterDataUserTask($event.target.value);"
                                        class="selectFilter primary-color d-inline-block font-16 font-league h-30 pl-2" style="border:2px solid; border-radius: 10px; width: 90% !important;">
                                        <option class="text-uppercase" [value]="'ALL'">{{'ALL' | translate}}</option>
                                        <option class="text-uppercase" [value]="'NEWEST'">{{'NEWEST' | translate}}</option>
                                        <option class="text-uppercase" [value]="'OLDEST'">{{'OLDEST' | translate}}</option>
                                        <option class="text-uppercase" [value]="'PROJECT'">{{'PROJECT' | translate}}</option>
                                        <option class="text-uppercase" [value]="'COMPLETED'">{{'COMPLETED' | translate}}</option>
                                    </select>
                                    </div>
                                   
                                    <div class="col-4 justify-content-end">
                                        <div class="input-search-box" style="height: 60%;">
                                            <i class="fa fa-search pointer-event-none" style="position: none !important; display: inline-block !important; top: 6px;" aria-hidden="true"></i>
                                            <input type="text"  style="height: 30px; display: inline-block !important;"   [(ngModel)]="taskSearch"
                                                class="pl-5 input-search form-control font-20 font-league bg-none border-0 txt-dark-blue text-uppercase">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <span class="btn btn-first font-14" data-toggle="modal" data-target="#createTaskModal" (click)="openTaskModal = true;getAllProjectDataName()"  >
                                            {{'Create Task' | translate}}
                                        </span>
                                    </div>
                                </div>
                                <ul class="userInfoList">
                                    <ng-container *ngFor = "let note of projectNoteList | globalSearch:taskSearch | globalSearch:completedFilterUser">
                                        <li class="notifyUserList"  style="border-bottom: 1px solid #e0e0e0;">
                                            <span *ngIf="!note.note">
                                                <span class="circleDot-1" title="{{'Mark Complete'}}" *ngIf= "note.status=='To Do'" (click)="changeToggle(note)"></span>
                                                <span class="circleDot" title="{{'Mark To Do'}}" *ngIf= "note.status=='Done'" (click)="changeToggle(note)"></span>
                                            </span>
                                            <span *ngIf="note.note">
                                                <span class="circleDot-1" *ngIf= "note.notificationChecked==false" (click)="changeToggle(note)"></span>
                                                <span  class="circleDot" *ngIf= "note.notificationChecked==true"></span>
                                            </span>
                                            <h5 class="userName notification-on" >{{note && note.subject?note.subject:''}}<span class="activeTime ml-3" style="float: none !important;">{{note.date | date:derivedSettings.dateFormatLine:''}}</span></h5>
                                            <p class="notification-details font-bold mt-4" >{{settings.projectLabel}}: {{note && note.projectName?note.projectName:''}} 
                                                <span *ngIf="note.note" class="activeTime ml-3" ><i class="fas fa-sticky-note"></i></span>
                                                <span *ngIf="!note.note" class="activeTime ml-3" ><i class="fas fa-tasks"></i></span>
                                            </p>
                                        </li>
                                    </ng-container>
                                </ul>
                                <!-- <ul class="userInfoList1">
                                    <ng-container *ngFor = "let note of projectNoteList | globalSearch:notesSearch">
                                        <li class="notifyUserList" *ngIf= "note.notificationChecked==true">
                                           
                                            <h5 class="userName notification-on" *ngIf= "note.notificationChecked==true">{{note && note.subject?note.subject:''}}<span class="activeTime ml-3" style="float: none !important;">{{note.date | date:derivedSettings.dateFormatLine:''}}</span></h5>
                                            <p class="notification-details mt-4" *ngIf= "note.notificationChecked==true">{{settings.projectLabel | translate}} {{note && note.projectName?note.projectName:''}}
                                                <span *ngIf="note.note" class="activeTime ml-3" ><i class="fas fa-sticky-note"></i></span>
                                                <span *ngIf="!note.note" class="activeTime ml-3" ><i class="fas fa-tasks"></i></span>
                                            </p>
                                        </li>
                                    </ng-container>
                                </ul> -->
                             
                            </mat-tab>
                            <mat-tab *ngIf="checkforLocalAndSandbox()" >
                                <ng-template mat-tab-label>
                                    <mat-icon svgIcon="slack" class="mr-1"></mat-icon>
                                    Slack
                                  </ng-template>
                                <div class="row mx-0 d-flex align-items-center" style="border-bottom: 2px solid #eee; height: 60px; ">
                                    <div class="col-4 justify-content-start">
                                        <span style="font-weight: 600; font-size: 14px;">
                                            {{"Newest"}}
                                        </span>
                                    </div>
                                    <div class="col-2">
                    
                                    </div>
                                    <div class="col-6 justify-content-end">
                                        <div class="input-search-box" style="height: 60%;">
                                            <i class="fa fa-search pointer-event-none" style="position: none !important; display: inline-block !important;  top: 6px;" aria-hidden="true"></i>
                                            <input type="text"  style="height: 30px; display: inline-block !important;"
                                                class="pl-5 input-search form-control font-20 font-league bg-none border-0 txt-dark-blue text-uppercase">
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab *ngIf="checkforLocalAndSandbox()" >
                                <ng-template mat-tab-label>
                                    <!-- <mat-icon svgIcon="teams" class="mr-1"></mat-icon> -->
                                    Meets
                                  </ng-template>
                                <div class="row mx-0 d-flex align-items-center" style="border-bottom: 2px solid #eee; height: 60px; ">
                                    <div class="col-4 justify-content-start">
                                        <span style="font-weight: 600; font-size: 14px;">
                                            {{"Newest"}}
                                        </span>
                                    </div>
                                    <div class="col-2">
                    
                                    </div>
                                    <div class="col-6 justify-content-end">
                                        <div class="input-search-box" style="height: 60%;">
                                            <i class="fa fa-search pointer-event-none" style="position: none !important; display: inline-block !important;  top: 6px;" aria-hidden="true"></i>
                                            <input type="text"  style="height: 30px; display: inline-block !important;"
                                                class="pl-5 input-search form-control font-20 font-league bg-none border-0 txt-dark-blue text-uppercase">
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                          </mat-tab-group>
                    
                      
                        <!-- <p class="seeMoreTextLink">{{'See more' | translate}}</p> -->
                    </div>
                </div>
                <!-- Header Project Not end here -->
                <!-- USER CHAT -->
                <!-- <a href="javascript:void(0)" id="chatBox" title="{{'Chat' | translate}}" (click)="chatBox =! chatBox">
                    <i class="far fa-comment" aria-hidden="true" id="notificaton"></i>
                </a>                 -->
                <div class="chat-box" *ngIf="chatBox" id="chatBox" style="right: 10px;">
                    <ul class="userInfoList">
                        <li class="notifyUserList" (click)="replyToTeams()" style="padding-left: 5px !important;">
                            <a href="https://teams.live.com/_#/conversations/?ctx=chat" target="_blank" class="anchor-img">
                                <img src="../../assets/img/ms-team.png" class="notifyUserImg rounded" alt="">
                            </a>
                            <h5 class="userName">Yannick Defrenne<span class="activeTime">05:09:2022</span></h5>
                            <p>Working on chat UI. <span class="activeTimeRedirect"><a href="https://teams.live.com/_#/conversations/?ctx=chat" target="_blank">{{'Redirect to apps' | translate}}</a></span></p>
                            <p class="display-message">Men</p>
                        </li>
                        <li class="notifyUserList" (click)="replyToGoogleMeet()" style="padding-left: 5px !important;">
                            <a href="https://meet.google.com/" target="_blank" class="anchor-img">
                                <img src="../../assets/img/google-meet.png" class="notifyUserImg rounded" alt="">
                            </a>
                            <h5 class="userName">Yannick Defrenne<span class="activeTime">04:09:2022</span></h5>
                            <p>Working on chat UI <span class="activeTimeRedirect"><a href="https://meet.google.com/" target="_blank">{{'Redirect to apps' | translate}}</a></span></p>
                            <p class="display-message">Men</p>
                        </li>
                        <li class="notifyUserList" (click)="replyToSkype()" style="padding-left: 5px !important;">
                            <a href="https://web.skype.com/" target="_blank" class="anchor-img">
                                <img src="../../assets/img/skype.png" class="notifyUserImg rounded" alt="">
                            </a>
                            <h5 class="userName">Yannick Defrenne<span class="activeTime">03:09:2022</span></h5>
                            <p>Working on chat UI <span class="activeTimeRedirect"><a href="https://web.skype.com/" target="_blank">{{'Redirect to apps' | translate}}</a></span></p>
                            <p class="display-message">Men</p>
                        </li>
                        <li *ngIf="teamsReply">
                            <div class="input-chat-div">
                                <textarea class="form-control pl-5 text-dark mt-2" placeholder="Reply On Teams" type="text" name="textarea" [(ngModel)]="textArea"></textarea>
                                <button (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀</button>
                            </div>
                             <emoji-mart class="emoji-mart text-left" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart>
                        </li>
                        <li *ngIf="googmeetReply">
                            <div class="input-chat-div">
                                <textarea class="form-control pl-5 text-dark mt-2" placeholder="Reply On Meet" type="text" name="textarea" [(ngModel)]="textArea"></textarea>
                                <button (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀</button>
                            </div>
                             <emoji-mart class="emoji-mart text-left" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart>
                        </li>
                        <li *ngIf="skypeReply">
                            <div class="input-chat-div">
                                <textarea class="form-control pl-5 text-dark mt-2" placeholder="Reply On Skype" type="text" name="textarea" [(ngModel)]="textArea"></textarea>
                                <button (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">😀</button>
                            </div>
                             <emoji-mart class="emoji-mart text-left" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart>
                        </li>
                    </ul>
                </div>
                <div id="chatbar" class="page-chatbar open" *ngIf="notificationClick">
                    <div class="chatbar-contacts" [ngClass]="{'show-user-list':showUserList,'show-message':!showUserList}">
                        <div class="contacts-search" id="contacts-search" #contactSearch>
                            <input type="text" [(ngModel)]="searchUserKeyword" class="searchinput font-14" placeholder="{{'Search Contacts' | translate}}">
                            <i class="searchicon fa fa-search mt-1 font-14"></i>
                        </div>

                        <!-- chat-log-status start-->
                        <div class="chat-log-status">
                            <ul class="status-icon-list">
                                <li>
                                    <a href="javascript:void(0);" (click)="selectedTab = 1">
                                        <i class="fa fa-comment-o"></i>
                                    </a>
                                    <p class="ico-text">{{'Chat' | translate}}</p>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" (click)="selectedTab = 2">
                                        <img src="../../assets/img/group-chat.png" alt="" />
                                    </a>
                                    <p class="ico-text">{{'Groups' | translate}}</p>
                                </li>
                            </ul>
                        </div>
                        <!-- chat-log-status end-->

                        <div class="slimScrollDiv" id="contactList" #contactList style="position: relative; overflow: auto; width: auto;max-height: calc(100% - 90px); margin-bottom: 30px;">
                            <ul class="contacts-list" style="overflow: auto; width: auto;">
                                <ng-container *ngFor="let user of userList | globalSearch:searchUserKeyword:['fullName']">
                                    <li *ngIf="user.id!=from && user.user_type == selectedTab" class="contact" (click)="onSelectFriend(user)">
                                        <span *ngIf="notificationsObject[user.index] && notificationsObject[user.index]['count']>0" class="userMsgNotify">
                                            {{notificationsObject[user.index]['count']}}
                                        </span>
                                        <div class="contact-avatar">
                                            <img [src]="user.hasOwnProperty('photo') && user['photo'].hasOwnProperty('url')?user['photo'].url:'../../assets/img/lg-avtar.png'">
                                        </div>
                                        <div class="contact-info">
                                            <div class="contact-name">{{user.firstName + ' ' + user.lastName}}</div>
                                            <div class="contact-status" *ngIf="user.user_type == 1">
                                                <div [ngClass]="{'online': allOnlineUsersList.hasOwnProperty(user.id),
                                            'offline': !allOnlineUsersList.hasOwnProperty(user.id)
                                            }">
                                                </div>
                                                <div class="status">
                                                    {{ allOnlineUsersList.hasOwnProperty(user.id)?'online':'offline' }}
                                                </div>
                                            </div>
                                            <div class="last-chat-time">
                                                <!-- last week -->
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                            <div class="slimScrollBar" style="background: rgb(45, 195, 232); width: 4px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; left: 1px; height: 857px;">
                            </div>
                            <div class="slimScrollRail" style="width: 4px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; left: 1px;">
                            </div>
                        </div>
                    </div>
                    <div class="chatbar-messages ng-scope">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="contacts-search" id="contacts-search" #contactSearch>
                                    <input type="text" class="searchinput font-14" placeholder="Search...">
                                    <i class="searchicon fa fa-search font-14 mt-2"></i>
                                </div>
                            </div>
                            <div class="col-sm-4 chat-top-navigation-icon">
                                <i class="fa fa-circle" aria-hidden="true"></i>

                                <a href="javascript:void(0)" id="statusMod" class="statusMod" (click)="showStatus = !showStatus">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <div class="status-menu" *ngIf="showStatus">
                                        <ul>
                                            <li>{{'Online' | translate}}</li>
                                            <li>{{'Away' | translate}}</li>
                                            <li>{{'Do not disturb' | translate}}</li>
                                            <li>{{'Invisible' | translate}}</li>
                                        </ul>
                                    </div>
                                </a>
                                <a href="javascript:void(0)" class="statusMod" id="attachment" (click)="showAttachment = !showAttachment">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    <div class="status-menu" *ngIf="showAttachment">
                                        <ul>
                                            <li>{{'Attachment 1' | translate}}</li>
                                            <li>{{'Attachment 2' | translate}}</li>
                                            <li>{{'Attachment 3' | translate}}</li>
                                            <li>More..</li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="messages-contact text-left">
                            <div class="contact-avatar">
                                <img [src]="toUserObject.hasOwnProperty('photo') && toUserObject['photo'].hasOwnProperty('url')?toUserObject['photo'].url:'../../assets/img/lg-avtar.png'">
                            </div>
                            <div class="contact-info">
                                <div class="contact-name">
                                    <!-- Divyia Philips -->
                                    {{ toUserObject?toUserObject.firstName + ' ' + toUserObject.lastName:'' }}

                                </div>
                                <div class="contact-status">
                                    <div [ngClass]="{'online': allOnlineUsersList.hasOwnProperty(toUserObject.id),
                                    'offline': !allOnlineUsersList.hasOwnProperty(toUserObject.id)
                                    }">
                                    </div>
                                    <div class="status">
                                        {{ allOnlineUsersList.hasOwnProperty(toUserObject.id)?'online':'offline' }}
                                    </div>
                                </div>
                                <div class="last-chat-time">
                                    <!-- a moment ago -->
                                </div>
                                <div class="back">
                                    <i class="fa fa-arrow-circle-left" (click)="showUsersList()"></i>
                                    <!-- <a href="javascript:void(0)" ><i class="fa fa-arrow-circle-left" (click)="showUsersList()"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <div class="chat-msg-container">
                            <div id="messages" class="slimScrollDiv" style=" height: 100%;position: relative; overflow: auto; width: auto; background-color: #fff; ">
                                <ul class="messages-list" style="overflow: auto; width: auto; ">
                                    <li *ngFor="let message of allMessageList" [ngClass]="message.message_from == from?'reply':'text-left'" class="message">
                                        <div class="message-info">
                                            <div class="bullet"></div>
                                            <div class="contact-name" *ngIf="selectedTab == 1">
                                                {{message.message_from == from?'Me': toUserObject.firstName + ' ' + toUserObject.lastName}}

                                            </div>
                                            <div class="contact-name" *ngIf="selectedTab == 2">
                                                {{message.sender_name}}
                                            </div>
                                            <div class="message-time">{{getTimeFormat(message.message_send_time)}}, {{getDateFormat(message.message_send_time)}}
                                            </div>
                                        </div>
                                        <div class="message-body">
                                            {{message.message}}
                                            <!-- Hi, Hope all is good. Are we meeting today? -->
                                        </div>
                                    </li>
                                    <!-- <li class="message reply">
                                        <div class="message-info">
                                            <div class="bullet"></div>
                                            <div class="contact-name">Divyia</div>
                                            <div class="message-time">10:15 AM, Today</div>
                                        </div>
                                        <div class="message-body">
                                            Hi, Hope all is good. Are we meeting today?
                                        </div>
                                    </li>
                                    <li class="message text-left">
                                        <div class="message-info">
                                            <div class="bullet"></div>
                                            <div class="contact-name">Me</div>
                                            <div class="message-time">10:14 AM, Today</div>
                                        </div>
                                        <div class="message-body">
                                            Hi, Hope all is good. Are we meeting today?
                                        </div>
                                    </li>
                                    <li class="message reply">
                                        <div class="message-info">
                                            <div class="bullet"></div>
                                            <div class="contact-name">Divyia</div>
                                            <div class="message-time">10:15 AM, Today</div>
                                        </div>
                                        <div class="message-body">
                                            Hi, Hope all is good. Are we meeting today?
                                        </div>
                                    </li>
                                    <li class="message text-left">
                                        <div class="message-info">
                                            <div class="bullet"></div>
                                            <div class="contact-name">Me</div>
                                            <div class="message-time">10:14 AM, Today</div>
                                        </div>
                                        <div class="message-body">
                                            Hi, Hope all is good. Are we meeting today?
                                        </div>
                                    </li>
                                    <li class="message reply">
                                        <div class="message-info">
                                            <div class="bullet"></div>
                                            <div class="contact-name">Divyia</div>
                                            <div class="message-time">10:15 AM, Today</div>
                                        </div>
                                        <div class="message-body">
                                            Hi, Hope all is good. Are we meeting today?
                                        </div>
                                    </li> -->
                                </ul>
                                <div class="slimScrollBar" style="background: rgb(45, 195, 232); width: 4px; position: absolute; top: 0px; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; left: 1px;">
                                </div>
                                <div class="slimScrollRail" style="width: 4px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; left: 1px;">
                                </div>

                            </div>

                            <div *ngIf="!showUserList" class="send-message">
                                <p class="usertypingText" *ngIf="isUserTyping">`
                                    <!--  is typing... -->
                                    <!-- {{toUserObject.firstName + " is typing..."}} -->
                                    {{returnTypingName(toUserObject) + " is typing..."}}
                                    <!-- *ngIf="isUserTyping" -->
                                </p>
                                <span class="input-icon icon-right">
                                    <textarea [(ngModel)]="msg" (keyup)="onKeyUp($event)" rows="4" class="form-control"
                                        placeholder="{{'Type your message' | translate}}"></textarea>
                                </span>
                                <div class="col-sm-10 text-left">
                                    <a href="javascript:void(0)" class="fa-chat-icon">
                                        <i class="fa fa-at"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="fa-chat-icon">
                                        <img src="../../assets/img/copy-url.png" class="img-fluid">
                                    </a>
                                    <a href="javascript:void(0)" class="fa-chat-icon">
                                        <i class="fa fa-paperclip"></i>
                                    </a>
                                </div>
                                <div class="col-sm-2 text-right">
                                    <i class="fa fa-send themeprimary ml-10" (click)="sendMessage()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- USER NOTIFICATION --> 
                <a href="javascript:void(0)" id="message" (click)="messageListClick =!messageListClick" title="{{'Notification' | translate}}"> 
                    <span>
                        <i class="far fa-bell" aria-hidden="true"></i>
                        <span class="notification-icon" *ngIf="countNotification > 0">{{countNotification}}</span>
                    </span>
                    <div class="userNotificationDropDown" *ngIf="messageListClick" style="left:-240px;">
                        <ul class="userInfoList">
                            <li *ngFor="let notificationData of userNotificationList ; let i = index" class="notifyUserList" style="padding-bottom: 22px !important; overflow: hidden;" (click)="updateNotificationStatus($event, notificationData)">
                                <div class="notifyUserImg rounded span-circle-box ml-3">
                                    <span class="d-inline-block shadow-sm mx-1 font-roboto-cad text-uppercase" *ngIf="notificationData.staffTheme" title="{{notificationData.requesterName}}"
                                        [ngStyle]="{'background-color':notificationData.staffTheme,'color':notificationData.color}">
                                        {{getShortName(notificationData.requesterName)}}
                                    </span>
                                    <span class="d-inline-block shadow-sm mx-1 font-roboto-cad text-uppercase" *ngIf="notificationData.staffTheme == undefined"
                                        title="{{notificationData.requesterName}}">
                                        {{getShortName(notificationData.requesterName)}}
                                    </span>
                       
                            </div>
                                <!-- <img src="../../assets/img/lg-avtar.png" class="notifyUserImg rounded" alt=""> -->
                                <p class="userName" [ngClass]="!notificationData.readStatus?'notification-on':''" (click)="redirectToLeaveManagement(notificationData)" >{{removeTags(notificationData.message) | translate | slice:0:40}} <span class="activeTime">{{notificationData.displayTime}}</span></p>
                                <p class="display-message mt-5" *ngIf="expandedNotificationId == notificationData.id">{{removeTags(notificationData.message)}}</p>
                                <i class="fa fa-trash" aria-hidden="true" title="{{'Delete notification' | translate}}" (click)="deleteNotificationById($event, i, notificationData)"></i>
                                <p [ngClass]="!notificationData.readStatus? 'notification-details':''" style="font-weight: 600;">{{notificationData.messageTitle | translate}}  <span *ngIf="notificationData.projectId != 0" title="{{'Redirect to Project' | translate}}" (click)="goToDetails(notificationData)" class="activeTimeRedirect"><i class="fas fa-arrow-circle-right"></i></span></p>
                               
                                <!-- <div class="row mt-2" *ngIf="notificationData.taskStatus != undefined && notificationData.taskStatus != null && notificationData.taskStatus == 'Awaiting Confirmation' && expandedNotificationId == notificationData.id">
                                    <div class="col-3">
                                        <button class="btn btn-first font-12" style="background-color: forestgreen;"  (click)="acceptOrdeclinereason(notificationData.id,'Confirm'); $event.stopPropagation();">Accept</button>
                                    </div>
                                    <div class="col-3">
                                        <button class="btn btn-first font-12"  data-toggle="modal" [attr.data-target]="'#' + notificationData.id" (click)="openCommentModal($event,notificationData.id)"  style="background-color: #e93220">Decline</button>
                                    </div>
                                </div> -->
                     

                            </li>
                        </ul>
                        <div class="px-4 d-flex justify-content-between">
                            <p *ngIf="!noMoreNotifications" class="seeMoreTextLink font-14" (click)="seeMoreNotifications($event)">{{'See more' | translate}}</p>
                            <p *ngIf="noMoreNotifications" class="seeMoreTextLink">{{'All notifications shown' | translate}}</p>
                            <p class="seeMoreTextLink text-danger mt-2" (click)="deleteNotificationAll($event)" title="{{'Delete All' | translate}}">
                                <span class="material-icons">
                                    delete_outline
                                </span>
                            </p>
                        </div>
                    </div>
                </a>

                <!-- Help Notification -->
                <a href="javascript:void(0)" id="helpNotification" (click)="openHelp =!openHelp">
                    <i class="fa fa-question"></i>
                    <div class="helpNotificationbar" *ngIf="openHelp">
                        <ul>
                            <li data-toggle="modal" data-target="#tutorialModal">
                                <div class="col-sm-2">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                </div>
                                <div class="col-sm-10 pt-2" (click)="openCreateProject()">
                                    {{'Create a New Project'|translate}}
                                </div>
                            </li>
                            <li>
                                <div class="col-sm-2">
                                    <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                                </div>
                                <div class="col-sm-10 pt-2" (click)="openHelpCenter()">
                                    {{'Training Content'|translate}}
                                </div>

                            </li>
                            <li *ngIf="showSupport">
                                <div class="col-sm-2">
                                    <i class="fa fa-life-ring" aria-hidden="true"></i>
                                </div>
                                <div class="col-sm-10 pt-2" (click)="generateSupportPassword()">
                                    {{'Support Access'|translate}}
                                </div>
                            </li>
                            <li *ngIf="showSupport">
                                <div class="col-sm-2">
                                    <i class="fa fa-life-ring" aria-hidden="true"></i>
                                </div>
                                <div class="col-sm-10 pt-2" (click)="revokeSupportAccess()">
                                    {{'Revoke Support Access'|translate}}
                                </div>
                            </li>
                            <li>
                                <div class="col-sm-2">
                                    <i aria-hidden="true" class="fa fa-comment-o"></i>
                                </div>
                                <div class="col-sm-10 pt-2">
                                    {{'Chat to support'|translate}}
                                </div>
                            </li>
                            <li>
                                <div class="col-sm-2">
                                    <i class="fa fa-history" aria-hidden="true"></i>
                                </div>
                                <div class="col-sm-10 pt-2">
                                    {{'Version'|translate}}
                                    <span class="font-15 text-active-primary-color ml-4">
                                        {{this.loggedInUserDetails?.tmVersion}}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </a>
                <!-------Marketplace Ui------------->
                <a href="javascript:void(0)" id="marketplace" title="{{'Marketplace' | translate}}" (click)="showMarketplace =! showMarketplace">
                    <!-- <img src="../../assets/img/market-place.png" alt="" > -->
                    <i class="fas fa-store"></i>
                    <!-- <i class="fas fa-store"></i> -->
                </a>
                <div class="marketplace" *ngIf="showMarketplace">
                    <form [formGroup]="marketPlaceForm">
                        <ul>
                            <li>
                                <label class="switch">
                                <input type="checkbox" class="ng-untouched ng-pristine" formControlName="globalResource" (change)="saveMarketPlace()">
                                <span class="slider round"></span>
                            </label>
                                <label for="" class="ml-2">{{'Global Resource' | translate}}</label>
                            </li>
                            <li>
                                <label class="switch">
                                <input type="checkbox" class="ng-untouched ng-pristine" formControlName="globalPersonnel" (change)="saveMarketPlace()">
                                <span class="slider round"></span>
                            </label>
                                <label for="" class="ml-2">{{'Global Personnel' | translate}}</label>
                            </li>
                        </ul>
                    </form>
                </div>
            </li>
        </ul>
    </div>
</div>
<!-- Two Factor Modal -->
<div id="myModal" class="modal in lite-modal" style="display: block;  top: 54px;" *ngIf="twoFaModal">
    <div class=" modal-dialog modal-md" style="width: 500px;">
        <div class="modal-content template-name">
            <div class="modal-header">
                <h4 class="modal-title font-18 text-uppercase font-league txt-light-gog">{{'Register for Two-factor Authentication' | translate}}</h4>
                <button class="close font-14" (click)="close2FAModal()" type="button">&#10005;</button>
                <!-- <h4 class="modal-title">{{'Register for 2 Factor Authentification' | translate}}</h4> -->
            </div> 
                <div class="modal-body px-5">
                    <div class="signin" (window:resize)="onResize($event)" [style.minHeight.px]="myInnerHeight" style="min-height: 754px;">
                        <div class="inner-page">
                            <h3 class="title3 m-0 text-center font-roboto-cad" style="font-size: 20px;">Sign In - Production Platform</h3>
                            <div class="basic-form text-center mt-10">
                                <div class="input-row">
                                    <img id="tokenQr" [src]="qrPath" class="img-thumbnail" style="width: 50px; height: 50px;"/>
                                    <h3><span>{{tokenValue}}</span></h3>
                                    <input type="text" [(ngModel)]="totpValue" placeholder="Enter Your Code" required class="form-control wp-60 m-auto-y">
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="input-row text-center">
                                <p class="m-0 font-14 font-roboto-cad">Download the app</p>
                                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="a-android">
                                    <i class="fa fa-android" aria-hidden="true"></i>
                                </a>
                                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" class="a-apple">
                                    <i class="fa fa-apple" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                      </div>
                </div>
                <div class="modal-footer p-4">
                    <button class="btn btn-first font-12" (click)="verifyTOTPNew()" type="submit">Enter</button>
                </div> 
        </div>
    </div>
</div>
<app-digital-signature *ngIf="showDigitalSignatureModal" [staffId]="loggedInUserDetails.id" (closeModalEvent)="showDigitalSignatureModal = false"></app-digital-signature>
<app-reset-password-myaccount *ngIf="showResetPasswordModal" (closeModalEvent)="showResetPasswordModal = false"></app-reset-password-myaccount>

<!-- Tutorial Modal -->
<div class="modal" id="tutorialModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close font-14 text-danger" data-dismiss="modal">&#10005;</button>
        </div>  
        <!-- Modal body -->
        <div class="modal-body px-5">
            <div class="row mx-0">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" [ngClass]="selectedStep!=1?'hide':''">
                    <div class="row mx-0">
                        <img src="https://images.unsplash.com/photo-1589362281138-e3f7ebe47f1a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=600&ixid=MnwxfDB8MXxyYW5kb218MHx8bGFwdG9wfHx8fHx8MTY2Mzc1MTU0NA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000" alt="" class="w-100 border-radius-15">
                    </div>
                    <div class="row mx-0 mt-3">
                        <h1 class="font-roboto-cad txt-light-gog font-16 w-100">Dasgboard View</h1>
                        <p class="font-roboto-cad txt-light-gog font-14 w-100">Seamless collaboration through rich and interactive calendar views.</p>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" [ngClass]="selectedStep!=2?'hide':''">
                    <div class="row mx-0">
                        <img src="https://images.unsplash.com/photo-1593642702821-c8da6771f0c6?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=600&ixid=MnwxfDB8MXxyYW5kb218MHx8bGFwdG9wfHx8fHx8MTY2Mzc1MTQ5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000" alt="" class="w-100 border-radius-15">
                    </div>
                    <div class="row mx-0 mt-3">
                        <h1 class="font-roboto-cad txt-light-gog font-16 w-100">Show View</h1>
                        <p class="font-roboto-cad txt-light-gog font-14 w-100">Visual interactive resource scheduling and calendar management.</p>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" [ngClass]="selectedStep!=3?'hide':''">
                    <div class="row mx-0">
                        <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=600&ixid=MnwxfDB8MXxyYW5kb218MHx8bGFwdG9wfHx8fHx8MTY2Mzc1MTUyMQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000" alt="" class="w-100 border-radius-15">
                    </div>
                    <div class="row mx-0 mt-3">
                        <h1 class="font-roboto-cad txt-light-gog font-16 w-100">Project View</h1>
                        <p class="font-roboto-cad txt-light-gog font-14 w-100">Budgeting / quotations, actualized costs, invoicing, P&L management and more.</p>
                    </div>
                </div>
            </div>
        </div>  
        <!-- Modal footer -->
        <div class="modal-footer p-4">
            <button class="btn btn-first mr-auto font-16" [ngClass]="selectedStep>1?'':'hide'" (click)="selectedStep =selectedStep-1" title="{{'Previous' | translate}}">
                <i class="fas fa-chevron-left"></i>
            </button>
            <button class="btn btn-first font-16" [ngClass]="selectedStep==3?'hide':''" (click)="selectedStep = selectedStep+1" title="{{'Next' | translate}}">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>  
      </div>
    </div>
</div>


<!-- data-backdrop="static" data-keyboard="false" -->

<div class="modal" [attr.id]="selectedNotificationmodal" tabindex="-1"  >
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-dark-blue">
        <div class="modal-header">
            <h4
                class="modal-title text-white font-18">
                {{'Reason'}}</h4>
            <button type="button"
                class="close font-14"
                
                data-dismiss="modal">&#10005;</button>
        </div>
        <div class="modal-body px-5">
            <div class="container-fluid px-0">
                <textarea name="" id=""
                [(ngModel)]="declineReason"
                    cols="30" rows="10"
                    class="form-control bg-none border-top-0 border-left-0 border-right-0 txt-light"
                    style="height: 150px;"
                    placeholder="{{'Write comment' | translate}}.."></textarea>
            </div>
        </div>
        <div class="modal-footer p-4">
            <button class="btn btn-add ml-auto"  (click)="acceptOrdeclinereason(selectedNotificationmodal,'Decline')"   >
                {{'Save & close'}}
            </button>
        </div>
    </div>
</div>
</div>

<div class="modal" id="createTaskModal" *ngIf="openTaskModal">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
            <h4
                class="modal-title font-18">
                {{'Create Task'}}</h4>
            <button type="button"
                class="close font-14"
                
                data-dismiss="modal">&#10005;</button>
        </div> 
        <!-- Modal body -->
        <div class="modal-body px-5">
            <div class="row mt-4">
                <div class="col-12" style="font-weight: 600; font-size: 14px;">
                    <label for="" class="label-control" style="font-weight: 600; font-size: 14px; ">{{'Select Project'|translate}}<span class="astrick">*</span></label>
                    <select name="" id="" class="form-control bg-none font-roboto-cad" style="width: 50%;" [(ngModel)]="recordId">
                        <option value="null" >{{'Select Project' | translate}}</option>
                        <option *ngFor="let obj of projectArr | sortAlpha:'projectname'" [value]="obj.id">{{obj.projectname}}</option>
                      </select>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12" style="font-weight: 600; font-size: 14px; ">
                    <textarea class="input-Pri_color border-top-0 border-right-0 border-left-0 bg-none" style="width: 70% !important;" [(ngModel)]="taskName"  placeholder="{{ 'Enter Task Name' | translate }}" ></textarea>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12" style="font-weight: 600; font-size: 14px; ">
                    <textarea class="input-Pri_color border-top-0 border-right-0 border-left-0 bg-none" style="width: 70% !important;" [(ngModel)]="taskDesc"  placeholder="{{ 'Enter Description' | translate }}" ></textarea>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12" style="font-weight: 600; font-size: 14px; ">
                <select   placeholder="Select Status" class="selectFilter primary-color d-inline-block font-16 font-league w-auto h-30 pl-2" [(ngModel)]="taskStatus" style="border:2px solid; border-radius: 10px; width: 20% !important;">
                        <option data-value="To Do">{{'To Do' | translate}}</option>
                        <option data-value="Done">{{'Done' | translate}}</option>
                </select>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12" style="font-weight: 600; font-size: 14px;">
                    <label for="" class="label-control" style="font-weight: 600; font-size: 14px; ">{{'Select Assignee'|translate}}</label>
                    <select name="" id="" class="form-control bg-none font-roboto-cad" style="width: 50%;" [(ngModel)]="taskAssignee">
                        <option value="null" >{{'Assignee' | translate}}</option>
                        <option *ngFor="let p of personnelList | globalSort:'fullName':sortValue" value={{p.id}}>{{p.fullName | translate}}</option>
                      </select>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12" style="font-weight: 600; font-size: 14px; border: none !important">
                    <label for="" class="label-control" style="font-weight: 600; font-size: 14px; display: block;">{{'Select Due Date'|translate}} <span class="astrick">*</span></label>
                    <p-calendar [locale]="derivedSettings.customLocale" class="s-right" placeholder="{{ 'Due Date' | translate }}" [(ngModel)]="taskDate"  [showTime]="true" dateFormat="{{derivedSettings.dateFormatCal}}" hourFormat="{{derivedSettings.timeFormateLine}}" [showIcon]="false" ></p-calendar>
                </div>
            </div>
            <div class="row mt-6">
                <div class="col-md-6">
                    <div class="mt-4 form-content">
                        <label class="font-roboto-cad txt-light-gog">{{'Attachment' | translate}}</label>
                        <div class="attachment-content mt-2">
                            <i class="fa fa-upload"></i>
                            <label for="">{{'Drag Here' | translate}}</label>
                            <label for="">{{'or Browse your computer' | translate}}</label>
                            <input type="file" class="fileType" [disabled]="docForm.get('validate').value" (change)="onFileChange($event)">
                        </div>
                        <span *ngIf="addedPictureNameMessage" style="color: hsl(147, 78%, 18%);text-align: left;font-size: 14px;margin-top: 5pt;">{{addedPictureNameMessage}}</span>
                        <span *ngIf="invalidDocFormatError" style="color: #a94442;text-align: left;font-size: 14px;margin-top: 5pt;">{{invalidDocFormatError}}</span>
                                         
                    </div>  
                </div>
            </div>
        </div>  
        <!-- Modal footer -->
        <div class="modal-footer p-4">
            <div class="modal-footer p-4">
                <button class="btn btn-add ml-auto" data-dismiss="modal"  (click)="saveTask(null)"   >
                    {{'Save & close'}}
                </button>
            </div>
        </div>  
      </div>
    </div>
</div>

