<!-- SIGNIN PAGE -->
<div class="container-fluid signin px-0" [style.minHeight.px]="myInnerHeight" *ngIf="vaildRecoveryToken">
    <div id="rock17"></div>
    <div id="rock18"></div>
    <div id="rock19"></div>
    <div id="rock20"></div>
    <div id="rock21"></div>
    <div id="rock22"></div>
    <div id="rock23"></div>
    <div id="rock24"></div>
    <div id="rock25"></div>
    <div id="rock26"></div>
    <div id="rock27"></div>
    <div id="rock28"></div>
    <div id="rock29"></div>
    <div id="rock30"></div>
    <div id="rock31"></div>
    <div id="rock32"></div>
  <div class="login-box row mx-0 font-roboto-cad">
      <div class="col-xl-6 mx-auto">
          <h3 class="title3 text-white text-center font-20">{{'Reset Password' | translate}}</h3>
          <div class="row mx-auto w-100 my-4">
              <img src="assets/img/signin-icon-white.png" alt="" class="logo1 mx-auto">
          </div>
          <div class="row mx-auto mt-4">
              <img src="assets/img/Teamium-white.png" alt="" class="img-fluid logo2 mx-auto">
              <p class="font-15 w-100 text-center text-white mt-2">PRODUCTION PLATFORM</p>
          </div>
      </div>

      <form [formGroup]="resetPasswordForm" class="w-100" style="display: grid;">
          <div class="login-box row mx-0 font-roboto-cad">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mx-auto">
                <div class="row mx-0 input-group mb-4">
                    <div class="form-group w-100" [ngClass]="{'valid':resetPasswordForm.controls['password'].valid}">
                        <!-- <span class="icon"><i class="fa fa-key"></i></span> -->
                        <input type="password" name="npassword" class="form-control modal-text-box" formControlName="password" required>
                        <label class="label-control">{{'New Password' | translate}}*</label>
                    </div>
                </div>
                <div class="row mx-0 input-group mb-4">
                    <div class="form-group w-100" [ngClass]="{'valid':resetPasswordForm.controls['confirmPassword'].valid}">
                        <!-- <span class="icon"><i class="fa fa-key"></i></span> -->
                        <input type="password" class="form-control modal-text-box" name="cpassword" formControlName="confirmPassword" required>
                        <label class="label-control">{{'Confirm Password' | translate}}*</label>
                    </div>
                    <span *ngIf=" resetPasswordForm.get('confirmPassword').value && resetPasswordForm.get('confirmPassword').value.length !=0 && resetPasswordForm.get('confirmPassword').invalid" style="color: #a94442;text-align: left;font-size: 14px;margin-top: 5pt;">{{'Confirm password does not matched' | translate}}.</span>
                </div>
                <button class="btn btn-account" [disabled]="resetPasswordForm.invalid" (click)="reset()">{{'Save' | translate}}</button>
                <div class="row mx-0 justify-content-center">
                    <button class="btn btn-signin mx-2" data-toggle="tooltip" data-placement="top" title="{{'Dont have an account? Sign up' | translate}}" (click)="goSignup()">
                        <i class="fas fa-user-plus"></i>
                    </button>
                    <button class="btn btn-signin mx-2" title="{{'Already have an account? Sign in' | translate}}" (click)="goTosignIn()">
                        <i class="fas fa-sign-in-alt"></i>
                    </button>
                </div>
            </div>
          </div>
      </form>
  </div>
</div>

<div class="container-fluid signin px-0" [style.minHeight.px]="myInnerHeight"  *ngIf="!vaildRecoveryToken" style="padding: 8% 0;">
    <div id="rock1"></div>
    <div id="rock2"></div>
    <div id="rock3"></div>
    <div id="rock4"></div>
    <div id="rock5"></div>
    <div id="rock6"></div>
    <div id="rock7"></div>
    <div id="rock8"></div>
    <div id="rock9"></div>
    <div id="rock10"></div>
    <div id="rock11"></div>
    <div id="rock12"></div>
    <div id="rock13"></div>
    <div id="rock14"></div>
    <div id="rock15"></div>
    <div id="rock16"></div>
    <div class="row mx-0 font-roboto-cad">
        <div class="col-xl-6 mx-auto">
            <h3 class="title3 text-white text-center font-20 text-uppercase">{{'Token expired or already validated' | translate}}.</h3>
            <div class="row mx-auto w-100 my-4">
                <img src="assets/img/signin-icon-white.png" alt="" class="logo1 mx-auto">
            </div>
            <div class="row mx-auto mt-4">
                <img src="assets/img/Teamium-white.png" alt="" class="img-fluid logo2 mx-auto">
                <p class="font-15 w-100 text-center text-white mt-2">PRODUCTION PLATFORM</p>
            </div>
        </div>
        <div class="row mx-0 w-100">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mx-auto text-center">
                <button class="btn btn-account" (click)="goToForgotPassowrd()">{{'Resend mail' | translate}}</button>
                <button class="btn btn-signin mx-2" data-toggle="tooltip" data-placement="top" title="{{'Sign up' | translate}}" (click)="goSignup()">
                    <i class="fas fa-user-plus"></i>
                </button>
                <button class="btn btn-signin mx-2" title="{{'Already have an account? Sign in' | translate}}" (click)="goTosignIn()">
                    <i class="fas fa-sign-in-alt"></i>
                </button>
            </div>
        </div>
    </div>
  </div>
