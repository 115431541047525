import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';  
import { HttpService } from '../core/services/http.service';
import { Observable,of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportChartsService {
  url = 'http://localhost:4200/teamiumapp/budget/project-actual-per-function-report-chart';  
  data=[]
  label = [];  
   ldataa=[{
                
            "booking": "Equipment>M_Camera",
            "resources": "M_Camera",
            "totalActual":5
        },
        {
                
          "booking": "Equipment>M_Camera",
          "resources": "M_Camera",
          "totalActual":10
        },
        {
                
          "booking": "PostProduction>Editor",
          "resources": "M_Camera",
          "totalActual":15
        }]
  constructor(private httpClient: HttpClient, private httpService: HttpService) { }

  public getData(): Observable<any>{
      return this.httpService.callApi('projectActualPerFunctionChart', {})
     }
     
  public getDataBookingWorkOrder(): Observable<any>{
      //return this.httpService.callApi('projectActualPerFunction1', {})
       
    return of(this.ldataa)
     }
    
//   public gethbarChartData(){
//     this.httpClient.get(this.url).subscribe((result: hBarChartData) => {  
//       return [result.hBarChartLabels,result.hBarChartData]

//   });
// }

//   public getPieChartData(){
//     this.httpClient.get(this.url).subscribe((result: pieChartData) => {  
//       return [result.pieChartLabels,result.pieChartData]
//   });
//   }

//   public getDoughnutChartData(){
//     this.httpClient.get(this.url).subscribe((result: doughnutChartData) => {  
//       return [result.doughnutChartLabels,result.doughnutChartLabels]

//   });

//}

}
