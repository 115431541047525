import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { SingleHeaderComponent } from '../single-header/single-header.component';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(public router: Router, private oauthService: OAuthService) {

   }

  ngOnInit() {
  }

  goSignin(){
            const claims = this.oauthService.getIdentityClaims();
            if (claims) {
            console.log("Okta activated for ", claims["name"], claims["email"]);
            }
            // changes to schedule-user temporarily because dashboard loading slow
            this.router.navigate(['/teamium/dashboard/agenda']);
  }
}
