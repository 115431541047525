import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService  implements ErrorHandler{

  constructor() { }
  handleError(err: any): void {
    console.log(err)
    
    // const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    // if (chunkFailedMessage.test(err.message)) {
    //   window.location.reload();
    // }
  }
}
