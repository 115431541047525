import { Injectable } from '@angular/core';

// import { BehaviorSubject } from 'rxjs';
// import { Observable } from 'rxjs/Observable';
// import { Observable, Subscriber } from 'rxjs';
// import * as io from 'socket.io-client';
// import * as Stomp from 'stompjs';
// import * as SockJS from 'sockjs-client';

import { ConfigService } from './config.service'

@Injectable()
export class ChatService {
  // private url = '';//'http://192.168.1.113:7000';  
  // private socket = null;
  // private subscriber: Subscriber<any> = null;
  // private connected: boolean = false;
  // private stompClient: any = null;
  // private id: number = 0;
  // private pendingProjectSubscriptions = [];
  // private messageSource = new BehaviorSubject<any>('chat_control_messages');
  // private currentMessage = this.messageSource.asObservable();

  constructor(public configService: ConfigService) {
    // this.url = 'http://' + configService.NODE_SERVER_HOST + ':' + configService.NODE_SERVE_PORT; //'http://192.168.1.113:7000';
  }
 
  // sendMessage(message) {
  //   if (this.socket) {
  //     this.socket.emit('sendMessage', message);
  //   }
  // }

  // uiControl() {
  //   return this.currentMessage;
  // }

  // openProjectChat(id: number) {
  //   const message = {
  //     command: "openProject",
  //     args: [id]
  //   };
  //   this.messageSource.next(message);
  // }

  // doOffline = (userId) => {
  //   let message = {
  //     type: 'doOffline',
  //     from: userId
  //   };
  //   this.stompClient.send(`/chat/control` , {}, JSON.stringify(message));
  // }

  // getConnectX(id) {
  //   let observable = new Observable(observer => {
  //     this.socket = io(this.url, { query: `id=${id}` });

  //     this.socket.on('connect', () => {
  //       let from = id|| '';
  //       let data={
  //         to:from
  //       }
  //       // this.getAllMessageNotifications(data);
  //     });

      

  //     this.socket.on('newUserConnect', (data) => {
  //       observer.next({ type: 'newUserConnect', data: data });
  //     });

  //     this.socket.on('getMessage', (data) => {
  //       observer.next({ type: 'getMessage', data: data });
  //     });

  //     this.socket.on('getAllMessage', (data) => {
  //       observer.next({ type: 'getAllMessage', data: data });
  //     });

  //     this.socket.on('getUserIsTyping', (data) => {
  //       observer.next({ type: 'getUserIsTyping', data: data });
  //     });


  //     this.socket.on('setAllMessageNotifications', (data) => {
  //       observer.next({ type: 'setAllMessageNotifications', data: data });
  //     });

  //     this.socket.on('newconnection', (data) => {
  //       observer.next({ type: 'newconnection', data: data });
  //     });

  //     return () => {
  //       this.socket.disconnect();
  //     };
  //   })
  //   return observable;
  // }

  // _initializeWebSocketConnection(){}
  // initializeWebSocketConnection(){
  //   let ws = new SockJS('/teamiumapp/ws?token=' + sessionStorage.getItem('token'));
  //   this.stompClient = Stomp.over(ws);
  //   let that = this;
  //   console.log('Connecting socket');
  //   this.stompClient.connect({'X-Authorization': 'Bearer ' + sessionStorage.getItem('token')}, function(frame) {
  //     console.log('CONNECTED socket');
  //     that.connected = true;
  //     that.stompClient.subscribe('/topic/broadcast', (message) => {
  //       if(message.body) {
  //         console.log("GOT message", message.body);
  //         that.routeMessage(JSON.parse(message.body));
  //       }
  //     });
  //     that.stompClient.subscribe('/user/queue/reply', (message) => {
  //       if(message.body) {
  //         console.log("GOT user message", message.body);
  //         that.routeMessage(JSON.parse(message.body));
  //       }
  //     });
      
  //     that.stompClient.subscribe('/user/queue/allmessages', (message) => {
  //       if(message.body) {
  //         console.log("GOT all messages");
  //         let messages: any[] = JSON.parse(message.body).reverse();
  //         that.subscriber.next({type: 'getAllMessage', data: messages});
  //       }
  //     });

  //     that.stompClient.subscribe('/user/queue/control', (message) => {
  //       if(message.body) {
  //         const msg = JSON.parse(message.body);
  //         that.routeMessage(msg);
  //       }
  //     });

  //     that.pendingProjectSubscriptions.forEach(element => {
  //       that.subscribeToProject(element);
  //     });
  //     that.broadcastNewUser();
  //   });
  // }

  // subscribeToProject(id: number) {
  //   if (this.connected) {
  //     console.log('SUBSCRIBE TO PROJECT', id);
  //     this.stompClient.subscribe('/topic/project/' + id, (message) => {
  //       console.log("GOT project message for ", message.body);
  //       this.routeMessage(JSON.parse(message.body));
  //     });
  //   } else {
  //     this.pendingProjectSubscriptions.push(id);
  //   }
  // }

  // broadcastNewUser() {
  //   let message = {
  //     type: 'newConnection',
  //     from: this.id,
  //     to: 0,
  //     data: ''
  //   }
  //   this.sendBroadcastMessage(message);
  // }

  // sendBroadcastMessage(message){
  //   console.log('Sending message')
  //   this.stompClient.send("/chat/broadcast" , {}, JSON.stringify(message));
  // }

  // sendMessage(message){
  //   console.log('Sending message', message);
  //   message.type = 'getMessage';
  //   if (message.msg_type === 1) {
  //     this.stompClient.send(`/chat/reply` , {}, JSON.stringify(message));
  //   } else {
  //     this.stompClient.send(`/chat/project/${message.to}` , {}, JSON.stringify(message));
  //   }
  // }

  // routeMessage(msg) {
  //   this.subscriber.next({type: msg['type'], data: msg});
  // }

  // getConnect(id) {
  //   this.id = id;
  //   setTimeout(() => this.initializeWebSocketConnection(), 1000);
  //   let observable = new Observable(observer => {
  //     this.subscriber = observer;
      
  //     return () => {
  //       // TODO: disconnect
  //     };
  //   });
  //   return observable;
  // }


  // getAllMessage = (message) => {
  //   console.log("Sending getAllMessage ", message);
  //   this.stompClient.send(`/chat/allmessages` , {}, JSON.stringify(message));
  // }

  // getAllMessageNotifications = (data)=>{
  //   // console.log(this.socket.id)
  //   if (this.socket) {
  //     data["socketId"]=this.socket.id;
  //     this.socket.emit('getAllMessageNotifications', data);
  //   }
  // }

  // setMessageSeenByUser = (data) =>{
  //   if (this.socket) {
  //     this.socket.emit('setMessageSeenByUser', data);
  //   }
  // }
 
  // setUserIsTyping = (data) =>{
  //   if (this.socket) {
  //     this.socket.emit('setUserIsTyping', data);
  //   }
  // }
}