import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SidebarMenuItem } from '../entity/sidebarmenuitem';

export interface SettingsData {
  projectLabel?: string;
  showLabel?: string;
  dateFormat?: string;
  dateFormatCal?: string;
  dateFormatLine?: string;
  timeFormat?: string;
  dayPilotTime?: string;
  companyLabel?: string;
  weekStart?:number;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public settings:SettingsData = {
    projectLabel: 'Project',
    showLabel: 'Show',
    dateFormat: 'DD/MM/YYYY HH:mm:ss',
    timeFormat: '12',
    companyLabel:'Company',
    weekStart:0
  };

  public derivedSettigs: any = {
    dateFormatCal : "dd/mm/yy",
    dateFormatLine : "dd/MM/yy",
    timeFormateLine : "h:mm a",
    dayPilotTime :"Clock12Hours",
    schedulerDate:"DD-MM-YYYY",
    schedulerTime:"HH:mm",
    dateFormat: 'dd/mm/yy HH:mm:ss',
    customLocale : {
      firstDayOfWeek: 1, // Monday
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    }
  }
  private fetched = false;
  private funcs = [];

  constructor(private httpService: HttpService) { 
    // console.log('Service init');
    this.fetch();
  }

  updateData(){
    if (this.settings.dateFormat === "MM/DD/YYYY HH:mm:ss") {
      this.derivedSettigs.dateFormatCal = "mm/dd/yy";
      this.derivedSettigs.dateFormatLine = "MM/dd/yy";
      this.derivedSettigs.schedulerDate="MM-DD-YYYY";
    } else {
      this.derivedSettigs.dateFormatCal = "dd/mm/yy";
      this.derivedSettigs.dateFormatLine = "dd/MM/yy"
    }

    if (this.settings.timeFormat === "24") {
      this.derivedSettigs.timeFormateLine = "H:mm";
      this.derivedSettigs.dayPilotTime = "Clock24Hours"
    }else {
      this.derivedSettigs.timeFormateLine = "h:mm a";
      this.derivedSettigs.schedulerTime = "hh:mm a"
      this.derivedSettigs.dayPilotTime = "Clock12Hours"
    }
  }

  private update(resp) {
    this.settings = resp;
    this.settings.companyLabel = resp.companyLabel;
    this.fetched = true;
    if (this.settings.dateFormat === "MM/DD/YYYY HH:mm:ss") {
      this.derivedSettigs.dateFormatCal = "mm/dd/yy";
      this.derivedSettigs.dateFormatLine = "MM/dd/yy";
      this.derivedSettigs.schedulerDate="MM-DD-YYYY";
    } else {
      this.derivedSettigs.dateFormatCal = "dd/mm/yy";
      this.derivedSettigs.dateFormatLine = "dd/MM/yy"
    }

    if (this.settings.timeFormat === "24") {
      this.derivedSettigs.timeFormateLine = "H:mm";
      this.derivedSettigs.dayPilotTime = "Clock24Hours"
    }else {
      this.derivedSettigs.timeFormateLine = "h:mm a";
      this.derivedSettigs.schedulerTime = "hh:mm a"
      this.derivedSettigs.dayPilotTime = "Clock12Hours"
    }
    // Update menu
    SidebarMenuItem[1].title = resp.showLabel;
    SidebarMenuItem[2].title = resp.projectLabel;
   // SidebarMenuItem[1].children[1].title = resp.projectLabel;
  //  SidebarMenuItem[3].children[4].title = resp.projectLabel;
    // SidebarMenuItem[3].children[5].title = resp.projectLabel + " Phase";
    //SidebarMenuItem[3].children[5].title = resp.showLabel;
    // SidebarMenuItem[3].children[7].title = resp.showLabel + " Cal";
    //SidebarMenuItem[5].children[1].title = resp.showLabel + " Reel";

    this.funcs.forEach(element => {
      element();
    });
    this.funcs = [];
  
  }

  fetch() {
    this.httpService.callApi('getSettings', {}).subscribe(resp => this.update(resp));
  }

  save(data: any) {
    this.httpService.callApi('setSettings', {body: data}).subscribe((resp) => {
      this.update(resp);
      document.location.reload();
    }
    );
  }

  
  call(fn: () => any) {
    if (!this.fetched) {
      this.funcs.push(fn);
    } else {
      fn();
    }
  }
}
