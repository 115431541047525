import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'sortAlpha',
  pure:false
})
export class SortAlphaPipe implements PipeTransform {

    transform(array: any, field: string): any[] {
      if (!array) {
        return [];
      }
        array.sort((a: any, b: any) => {
          if(a[field] != undefined && b[field] != undefined){
            if (a[field].toString().toLowerCase() < b[field].toString().toLowerCase()) {
    
              return -1;
      
            } else if (a[field].toString().toLowerCase() > b[field].toString().toLowerCase()) {
      
              return 1;
      
            } else {
      
              return 0;
      
            }
          } else {
            return 0;
          }
    
     
    
        });
    
        return array;
    }

}
