<!-- Modal -->

<div id="myModal" class="modal in   add-equipment-modal" role="dialog" style="display: block;  top: 54px;">
    <div class="modal-dialog  modal-md">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="true" (click)="closeModalDirect()">&times;</button>
                <h4 class="modal-title">{{'Digital ' | translate}}{{'Signature' | translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="create-equipment digital-signature">
                    <div class="inner-content">
                        <!-- 4  ATTACHMENTS -->
                        <div class="add-attachment pad-20">
                            <div class="row">
                                <div class="col-md-10 col-md-offset-1">
                                    <div class="form-content">
                                        <label>{{'Signature' | translate}}{{' Here' | translate}}</label>
                                        <div class="digital-Sign-Div mb-20" #signaturediv>
                                            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()"
                                                (onEndEvent)="drawComplete()"></signature-pad>
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-first pull-left" (click)="onUndo()">{{'Undo' | translate}}</button>
                                            <button type="submit" class="btn btn-first" (click)="onClear()">{{'Clear' | translate}}</button>
                                            <button type="submit" class="btn btn-first pull-right" (click)="onRedo()">{{'Redo' | translate}}</button>
                                        </div>
                                        <p class="or"></p>

                                        <!-- DRAG AND DROP -->
                                        <div>
                                            <!-- <label>Attachment Name</label>
                                            <input type="text" class="form-control" placeholder="Attachment Name"> -->
                                            <div class="attachment-content">
                                                <i class="fa fa-upload"></i>
                                                <label for="">{{'Drag Here' | translate}}</label>
                                                <label for="">{{'or Browse your computer' | translate}}</label>

                                                <input type="file" class="fileType" (change)="onFileChange($event)" #inputImage>
                                                <img [src]="pictureUrl" *ngIf="pictureUrl" style="position: absolute; width: 100%;height: 100%;left: 0px;top:0px;">
                                                <!-- Delete picture -->
                                                <i *ngIf="pictureUrl" style="position: absolute;top: -10px;right: -10px;font-size: 20px;color: red;opacity: 1;cursor:pointer;" class="fa fa-times-circle" title="Remove digital signature" (click)="removeImage()"></i>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer p-4">
                <button class="btn m-btn-delete" (click)="deleteSignature()">
                    {{'Delete'}}
                </button>
                <button type="submit" class="btn btn-add" (click)="saveDigitalSignature()">
                    {{'Save & close'}}
                </button>
            </div>
        </div>
    </div>

</div>