import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { sha256 } from 'crypto-hash'; // For generating SHA-256 hash
@Injectable({
  providedIn: 'root'
})
export class OutlookServiceService {


}
