declare var window: any;

export const environment = {
  production: true,
  get environment() {
    return window.config.environment;
  },
  firebaseConfig: {
    apiKey: "AIzaSyCXj_e5v3vt6WEqlZTPVEWEaLpYygGfKW0",
    authDomain: "sandbox-demo-9c091.firebaseapp.com",
    projectId: "sandbox-demo-9c091",
    storageBucket: "sandbox-demo-9c091.appspot.com",
    messagingSenderId: "209099531761",
    appId: "1:209099531761:web:56adbab440ae55b9b01050",
    vapidkey:"BIL_lPFT7_sS1eRCCwhOFZjjtBaKzr0jW7l75aHUvQcOrYCzx3mmVwCdtdP15E-KVjj2c8IugyhSEj3CjYs1CpY",
    measurementId: "G-HS1CHZZSZ8"
  },

  get feServerUrl() {
    return window.config.feServerUrl;
  }
};
