<div class="dashboard" id="dashboard">
    <app-header id="hello" class="logo-large"></app-header>
    <div class="page-content">
        <!-- ROUTED PAGES -->
        <div class="dashboard-pages-content">
            <app-sidebar class="sfsgf" id="closeIt" ></app-sidebar>
            <router-outlet>
              
            </router-outlet>

        </div>
    </div>
</div>