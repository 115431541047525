import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  colors: any;
  teamiumLight:string ='assets/themes/teamium-light.xml';
  teamiumDark:string ='assets/themes/teamium-dark.xml';
  darkMode:boolean = false
  constructor(private http: HttpClient) { }

  setDarkMode(){
    this.darkMode = true
    this.loadTheme().subscribe((colors) => {
        for (const key in colors) {
         if (Object.prototype.hasOwnProperty.call(colors, key)) {
           document.documentElement.style.setProperty(`--${key}`,colors[key])
   
         }
     }
     })
  }

  setLisghtMode(){
    this.darkMode = false
    this.loadTheme().subscribe((colors) => {
        for (const key in colors) {
         if (Object.prototype.hasOwnProperty.call(colors, key)) {
           document.documentElement.style.setProperty(`--${key}`,colors[key])
   
         }
     }
     })
  }

  loadTheme(): Observable<any> {
    // console.log(this.darkMode)
    return this.http.get(this.darkMode ? this.teamiumDark : this.teamiumLight , { responseType: 'text' }).pipe(
        map((xmlString: string) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
            const colors = {};
            const colorNodes = xmlDoc.getElementsByTagName('color');
            for (let i = 0; i < colorNodes.length; i++) {
                const name = colorNodes[i].getAttribute('name');
                // console.log(name)
                const value = colorNodes[i].textContent;
                colors[name] = value;
            }
            this.colors = colors;
            return colors;
        })
    );
}

getColor(name: string): string {
    return this.colors[name];
}
}
