import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss']
})
export class AuthentificationComponent implements OnInit {

  public myInnerHeight: any;
  
  constructor() { }

  ngOnInit() {
    this.myInnerHeight = window.innerHeight;   
  }

  onResize(event) {
    this.myInnerHeight = event.target.innerHeight;
  }

}
