
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgDragDropModule } from 'ng-drag-drop';
// import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { StartTourComponent } from './start-tour/start-tour.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SingleHeaderComponent } from './single-header/single-header.component';
import { DataService } from './data.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from './core/services/token-interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { PopoverModule } from "ngx-popover";

import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { EmbedVideo } from 'ngx-embed-video';


import { DatePipe } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {ChatService} from './core/services/chat.service' ;
import {SharedModule} from './shared.module';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import {ReportChartsModule} from './report-charts/report-charts.module';
import { SecureLoginComponent } from './secure-login/secure-login.component';
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";


import { OAuthModule } from 'angular-oauth2-oidc';
import { OktaService } from './core/services/okta.service';
import { AuthentificationComponent } from './authentification/authentification.component';
import {AutoSizeInputModule} from 'ngx-autosize-input';
import { SharedService } from './core/services/shared-service/shared.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { UserRoleService } from './core/services/user-role.service';
import { Canal9OktaService } from './core/services/canal9-okta.service';

// import {firebase}
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { FrameioService } from './core/services/frameio/frameio.service';
import { CustomTranslateLoader } from './core/services/trans-loader';
import { ThemeService } from './core/services/theme/theme.service';
import { AzureService } from './core/services/azure.service';
import { SessionTimeoutModalComponent } from './session-timeout-modal/session-timeout-modal.component';
import { OutlookServiceService } from './core/services/outlook-service.service';
initializeApp(environment.firebaseConfig);


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ".",
  precision: 0,
  prefix: "$",
  suffix: "",
  thousands: ","
};





export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [    
    AppComponent,
    SignupComponent,
    StartTourComponent,
    WelcomeComponent,
    SingleHeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SecureLoginComponent,
    AuthentificationComponent,
    SessionTimeoutModalComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CurrencyMaskModule,
    AutoSizeInputModule,
    UiSwitchModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        //useFactory: HttpLoaderFactory,
        useClass:CustomTranslateLoader,

        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    EmbedVideo.forRoot(),
    SharedModule,
    OAuthModule.forRoot(),
    ReportChartsModule,
  
  ],

  providers: [
    OktaService,Canal9OktaService,AzureService,
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    AppComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    OutlookServiceService,
    DataService,
    SharedService,
    ThemeService,
    FrameioService,
    UserRoleService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    DatePipe,
    ChatService,
    TranslateService
    ],
    
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
//  return new TranslateHttpLoader(
//   http,
//   'http://localhost:8081' + '/assets/i18n/',
//   '.json'
//   );
}