
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
//import { AppConfig } from "./config"
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service'
import { UrlsService } from './urls.service';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
    token = sessionStorage.getItem('token');   
    constructor(private http: HttpClient,private configService: ConfigService) {}
     getTranslation(lang: string): Observable<any>{
          var headers = new HttpHeaders({ 'Authorization': 'Bearer' + this.token, 'Content-Type': 'application/json' });
        var apiAddress='teamiumapp/configuration/getAllLanguages/'+lang;
         
    if(window.location.hostname == 'localhost'){
        //apiAddress ="http://localhost:8080/configuration/getAllLanguages/"+lang; 
       apiAddress=' https://sandbox.teamium.net/teamiumapp/configuration/getAllLanguages/'+lang;
      } else { 
        apiAddress = `${window.location.protocol}//${window.location.hostname}/teamiumapp/configuration/getAllLanguages/`+lang;
      }
         //var apiAddress=' https://sandbox.teamium.net/teamiumapp/configuration/getAllLanguages/'+lang
        // var apiAddress='/teamiumapp/configuration/getAllLanguages/'+lang;
        //var apiAddress = AppConfig.API_URL+"/static/i18n/"+ lang+".json";
        
        return Observable.create(observer => {
          this.http.get(apiAddress, { headers: headers }).subscribe((res: any) => {
                console.log(res)
                   observer.next(res);
                    observer.complete();               
                },
            error => {
                this.http.get("assets/i18n/en.json").subscribe((res: any) => {
                    observer.next(res);
                    observer.complete();               
                })
            }
            );
        }); 
    }
}