import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig, ActiveToast } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
import { TranslateLoader } from '@ngx-translate/core';
import { CustomTranslateLoader } from "./trans-loader";
import { templateJitUrl } from '@angular/compiler';
import { resolveTxt } from 'dns';
@Injectable({
  providedIn: 'root'
})
export class ToastrTranslateService {
  constructor(private toastrService: ToastrService, public translateText: TranslateService) {    }
  
  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any> {
    return this.toastrService.show(this.translateT(message), this.translateT(title), override, type);
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.toastrService.success(this.translateT(message), this.translateT(title), override);
  }

  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastrService.warning(this.translateT(message), this.translateT(title), override);
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastrService.info(this.translateT(message), this.translateT(title), override);
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastrService.warning(this.translateT(message), this.translateT(title), override);
  }

  clear(toastId?: number): void {
    this.toastrService.clear(toastId);
  }
  
  remove(toastId: number): boolean {
    return this.toastrService.remove(toastId);
  }

   public translateT(txt: string): string {
  //   this.translateText.currentLang;

  //     console.log(this.translateText)
  //     //console.log(this.translateText.instant("test"))

  if(txt=='')
  {
    return txt;
  }
  else{
     try {return this.translateText.instant(txt);
     }
     catch(e){
       return txt;
     }

    }

   }
  //public translateT(txt: string): string {
    //return txt;
  //}
}