import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { CommonUtilService } from '../common-util-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FrameioService {
  private client_ID:any = '53825374-5aaa-4dba-869a-84304de96436';
  private  client_secrate:any = 'Q6FR~lN_OnsEVOzDcTp1cLlL8N';
  AUTHORIZE_URL = "https://applications.frame.io/oauth2/auth"
  TOKEN_URL = "https://applications.frame.io/oauth2/token";
  REDIRECT_URI = "https://teamium.net/"
  SCOPE = "project.read project.create comment.create presentation.delete comment.delete  reviewlink.create team.update reviewlink.update webhook.update team.create reviewlink.read offline auditlog.read webhook.read presentation.read action.delete asset.update presentation.update project.delete webhook.create action.update presentation.create asset.delete asset.create asset.read webhook.delete action.create team.read action.read comment.read project.update comment.update account.read reviewlink.delete"
  
  userAccountInfo:any=null;
  framioTeams:any=[];
  // allProjectForUser:any=[];
  allMediaFromFrameio:any=[];

  private allProjectForUser = new BehaviorSubject([]);
  allProjectForUser$ = this.allProjectForUser.asObservable();

constructor(private commonUtilService:CommonUtilService,private toastr:ToastrService ) { }


// https://applications.frame.io/oauth2/auth?grant_type=authorization_code&scope=project.read+project.create+project.delete+project.update&response_type=code&redirect_uri=https%3A%2F%2Fwww.teamium.net%2Foauth%2Fcallback&state=623a1f59-d402-4ba7-ae24-335fa25749b7&client_id=267e386d-b16d-4a9c-a6b1-18ba313f6b80
  //  create_auth_url() {
  //   var credentials, url;
  //   credentials = {
  //     "response_type": "code",
  //     "redirect_uri": REDIRECT_URI,
  //     "client_id": CLIENT_ID,
  //     "scope": SCOPE,
  //     "state": uuid.uuid4().toString()
  //   };
  //   url = AUTHORIZE_URL + "?" + urlencode(credentials);
  //   return url;
  // }

  getallProjectForUser(): any {
    return this.allProjectForUser.getValue();
  }


  setallProjectForUser(data: any): void {
    this.allProjectForUser.next(data);
  }



  async createProjectFromFrameio(teamId:any,projectObj:any){
    try {
      const res:any = await this.commonUtilService.createProjectFromFrameio(teamId,projectObj);
      this.getAllProjectsFromFrameio(this.framioTeams[0].id)
      this.toastr.success('Project successfully linked with frame io')
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async getallCommentsFromFrameio(assetId:any){
    return new Promise(async(resolve, reject) => {
    try {
      const res:any = await this.commonUtilService.getallCommentsFromFrameio(assetId);
      return res
    } catch (error) {
      console.log(error)
      return false
    }
  });
  }

   async getFrameIoTeams(){
    return new Promise(async(resolve, reject) => {
  
      try {
        if(this.userAccountInfo == null){
            this.getUserAccountInfo();
            return
        }
  
        const res:any = await this.commonUtilService.getframeioTeams(this.userAccountInfo.account_id);
        this.framioTeams =  res;
        this.getAllProjectsFromFrameio(this.framioTeams[0].id)
        resolve(true);
        return true;
      } catch (error) {
        console.log(error)
        reject(false);;
  
       
      }
    });

  }

  async getUserAccountInfo(){
    try {
      const res:any = await this.commonUtilService.getUserAccountInfo();
      console.log(res)
      this.userAccountInfo = res;
      this.getFrameIoTeams();
    } catch (error) {
      console.log(error)
    }
  }

  async getAllProjectsFromFrameio(teamId){
    try {
      const res:any = await this.commonUtilService.getAllProjectsByTeam(teamId);
      // this.allProjectForUser = res;
      this.setallProjectForUser(res)
      // this.getAllAssestFromFrameio();
    } catch (error) {
      console.log(error)
    }
  }

  async getAllAssestFromFrameio(){
    try {
      
      if(this.userAccountInfo == null){
          this.getUserAccountInfo();
          return
      }

      const res:any = await this.commonUtilService.getframeioAssests(this.userAccountInfo.account_id);
      this.allMediaFromFrameio =  res;
      return true;
    } catch (error) {
      console.log(error)
      return false;

     
    }
  }
}
