import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secure-login',
  templateUrl: './secure-login.component.html',
  styleUrls: ['./secure-login.component.scss']
})
export class SecureLoginComponent implements OnInit {

  public myInnerHeight: any;

  constructor() { }

  ngOnInit() {
    this.myInnerHeight = window.innerHeight;   
  }

  onResize(event) {
    this.myInnerHeight = event.target.innerHeight;
  }

}
